import { useState } from 'react';
import firebase from '../database/FirebaseConfig';

const db = firebase.firestore();

function useCreateOcurrence(data: any) {
    db
        .collection('occurrences')
        .add(data)
}



const useGetAllOcurrences = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loading, setLoading] = useState(false);

    const getOcurrences = async ({ onComplete, company }: any) => {
        setLoading(true);
        db.collection("occurrences")
            .where('company', '==', company)
            .onSnapshot((querySnapshot: any) => {
                const occurrences = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id };
                });
                onComplete(occurrences);
                setLoading(false);
            }, err => {
                setLoading(false);
                console.log('erro: ', err);

            });


        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loading, getOcurrences, setLoading };
}

export { useGetAllOcurrences, useCreateOcurrence as default };