import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { AiOutlineSend } from "react-icons/ai";
import firebase from 'database/FirebaseConfig';
import axios from 'axios'

import checkbox from 'assets/icons/checkboxBlue.svg';
import { confirmAlert } from 'react-confirm-alert';
import { BsFillTrashFill, BsPencilSquare } from "react-icons/bs";
import { ResetPassword } from "components/ResetPassword";
import styled from "styled-components";
import { UserProps } from "components/ManagerAccounts";
import MaskCPFCNPJ from "utils/MaskCpfCnpj";
import { Companies } from "Page/PainelCompanieUsers";

import './index.scss';
import { Colors, Links } from "utils/template";


const StyledResetPassword = styled(ResetPassword)`
    height: 35px;
    `;

type AccountProps = {
  user: UserProps;
  themeHex?: string;
  company: Companies;
  closeModalUsers: () => void;
  deleteUserOnCompany?: (newUsers: any) => void;
}

export function Account({ user, themeHex, company, closeModalUsers, deleteUserOnCompany }: AccountProps) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [changeItem, setChangeItem] = useState(false);
  const [document, setDocument] = useState(user.document);
  const alert = useAlert();
  const color = themeHex ? themeHex : Colors.secondary;
  const db = firebase.firestore();
  const [toggleCheckBox, setToggleCheckBox] = useState(!!user?.access?.delivery);
  const [documentWithoutFormatation, setDocumentWithoutFormatation] = useState(user.document.replaceAll('.', '').replace('-', ''));

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setDocument(user.document);
    setToggleCheckBox(!!user?.access?.delivery);
    setDocumentWithoutFormatation(user.document.replaceAll('.', '').replace('-', ''));
  }, [user])

  const GetCompany = async (uid: string) => {
    const db = firebase.firestore();
    try {
      const doc = await db.collection('companies').doc(uid).get()
      const companyInfo = doc.data()
      return companyInfo
    }
    catch (err) {
      console.log("Não foi possível pegar a company", err)
    }
  };

  async function handleAccess(uid: string, value: number) {
    if (value === 0) {
      const waybillsSnapshot = await db.collection('waybills').where('company', '==', company.uid).get();
      const waybillsByCompanyData = waybillsSnapshot.docs.map(doc => doc.data());
      const driverInRomaneio = waybillsByCompanyData.find(waybill => waybill.driver === user.name && waybill.status !== 4);
      if (!!driverInRomaneio) {
        alert.error('Não foi possível excluir o motorista porque ele está associado ao romaneio')
        return;
      }
    }
    setToggleCheckBox(prevState => !prevState);

    try {
      if (!!uid) {
        db.collection('users').doc(uid).update({
          access: {
            ...user.access,
            delivery: value,
          }
        }).then(() => {
          if (value === 1) {
            alert.success("ACESSO LIBERADO!");
          } else {
            alert.success("ACESSO BLOQUEADO!");
          }
          console.log('Profile Successfully Edited!');
        }).catch((error: any) => {
          alert.error("ERRO AO MUDAR ACESSO!");
          console.log('Error updating the document:', error);
        })
        if (!!company.uid) {
          db.collection('users').doc(uid).update({
            company: company.uid,
          }).then(() => {
            console.log('Profile Successfully Edited!');
          }).catch((error: any) => {
            console.log('Error updating the document:', error);
          })
        }
      }
      db.collection('companies').doc(company.uid).update({
        [`users.${documentWithoutFormatation}`]: !!value
      })
    } catch (e) {
      alert.error("ERRO AO ALTERAR PERMISSÃO");
    }
  }

  async function handleSubmit() {
    var Format = {};
    const dataCompany = await GetCompany(user.company)
    let companyUsers = dataCompany?.users


    for (var data in companyUsers) {
      if (data !== documentWithoutFormatation) {
        Format = {
          ...Format,
          [data]: companyUsers[data],
        }
      } else {
        Format = {
          ...Format,
          [document]: Boolean(user.access.delivery),
        }
      }
    }

    db.collection('users').doc(user.uid).update({
      'email': email,
      'name': name,
    }).then(() => {
      console.log('Profile Successfully Edited!');
      alert.success('Alterado com sucesso');
    }).catch((error: any) => {
      console.log('Error updating the document:', error);
      alert.error('Erro ao atualizar');
    })

    db.collection('companies').doc(company.uid).update({
      'users': Format,
    }).then(() => {
      console.log('Ok');
    }).catch((error: any) => {
      console.log('Erro ao remover');
    })

    if (user.email !== email) {
      axios.put(
        Links.changeEmail,
        // 'https://us-central1-raster-homolog-89931.cloudfunctions.net/updatePassword',
        { id: user.uid, newEmail: email }
      ).then(() => {
        alert.success('Email atualizado com sucesso!');
      }).catch(e => {
        console.log(e)
        alert.error('Erro ao atualizar o email');
      })
    }
  }

  async function handleRemove() {
    var registerAccount = [];
    var Format = {};

    const waybillsSnapshot = await db.collection('waybills').where('company', '==', company.uid).get();
    const waybillsByCompanyData = waybillsSnapshot.docs.map(doc => doc.data());
    const driverInRomaneio = waybillsByCompanyData.find(waybill => waybill.driver === user.name && waybill.status !== 4);

    if (!!driverInRomaneio) {
      alert.error('Não foi possível excluir o motorista porque ele está associado ao romaneio')
      return;
    }

    for (var data in company.users) {
      if (data !== documentWithoutFormatation) {
        Format = {
          ...Format,
          [data]: company.users[data],
        }
        registerAccount.push(Format);
      }
    }

    if (!!deleteUserOnCompany) {
      deleteUserOnCompany(documentWithoutFormatation);
    }

    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    if (!!user.uid) {
      db.collection('users').doc(user.uid).update({ deletedAt: timestamp }).then(() => {
        alert.success('Removido com sucesso');
      }).catch((error: any) => {
        alert.error('Erro ao remover');
      })
    }

    db.collection('companies').doc(company.uid).update({
      'users': Format,
    }).then(() => {
      console.log('Ok')
      if (user.uid === undefined) alert.success('Removido com sucesso');
    }).catch((error: any) => {
      alert.error('Erro ao remover');
    })
  }

  const isDriver = user.role === 'driver'
  const userLogin = isDriver ? user?.phoneNumber ?? '' : user.email;
  const userDocumentFormatted = MaskCPFCNPJ(documentWithoutFormatation);

  return (
    <>
      {changeItem ?
        <textarea value={name} onChange={(event) => setName(event.target.value)} />
        :
        <span>{user.name ?? ''}</span>
      }

      <span className="bigContent">{userDocumentFormatted}</span>

      {changeItem ?
        <textarea
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
          }} />
        :
        <span className="bigContent">{userLogin}</span>
      }

      <span style={{ paddingLeft: 12 }}>{user.role}</span>

      <div
        className={toggleCheckBox ? ' label-active ' : 'containerCheckbox'}
        style={{ color: 'var(--secondary)' }}
      >
        <input
          type="checkbox"
          onClick={() => {
            // if (user?.access?.delivery === undefined) {
            //   alert.error('Este usuário necessita terminar o cadastro no aplicativo!')
            //   return;
            // };
            handleAccess(user.uid, 1)
          }}
        />
        {toggleCheckBox && (
          <img src={checkbox}
            alt="checkbox true"
            onClick={() => handleAccess(user.uid, 0)}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '2rem' }}>
        {!isDriver &&
          <StyledResetPassword
            style={{ background: color, width: 135 }}
            uid={user.uid}
            collection='users'
          >
            <span>Redefinir senha</span>
          </StyledResetPassword>
        }
        <div
          className="blockIconButtons"
          style={{
            display: "flex",
            paddingLeft: 15,
            justifyContent: "space-between",
            gap: '1rem',
            fontSize: '1.3rem',
            alignItems: "center",
            color: "var(--secondary)"
          }}>
          {!changeItem && !isDriver &&
            <BsPencilSquare
              style={{ cursor: 'pointer', marginRight: '0.2rem' }}
              onClick={() => setChangeItem(!changeItem)}
            />
          }
          {changeItem ? (
            <>
              <div className="buttonsContainer" style={{
                display: "block",
                alignItems: "flex-start",
              }}>
                <div className="sendButtonAccounts" onClick={() => {
                  handleSubmit();
                  setChangeItem(!changeItem)
                }}>
                  Salvar
                </div>
                <div className="cancelButtonAccounts" onClick={() => setChangeItem(!changeItem)}>
                  Cancelar
                </div>
              </div>

            </>
          ) : (
            <BsFillTrashFill
              style={{ cursor: 'pointer', zIndex: 999 }}
              onClick={() => {
                closeModalUsers();
                confirmAlert({
                  message: 'Deseja remover este usuário?',
                  buttons: [
                    {
                      label: 'Cancelar',
                      onClick: () => ('Cancelar')
                    },
                    {
                      label: 'Sim',
                      onClick: () => {
                        handleRemove();
                      }
                    }
                  ],
                  overlayClassName: 'confirmAlert'
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}


