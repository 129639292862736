import Cookies from "js-cookie";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { useAlert } from "react-alert";
import { useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineCloseCircle, AiOutlineDownload } from "react-icons/ai";
import { Timeline } from 'antd';
// commponents
import { CreateDocuments } from "../CreateDocuments";
import timestampToDate, { secondsToTime } from "utils/timestampToDate";

import {
  default as UpdateCardIntoDocument,
  RemoveStateWarningInCard,
  UpdateCardsToReturnDelivery,
} from "hooks/useUpdateCard";
import {
  UpdateAllOccurrencesToCheck,
  UpdateNotifications,
} from "hooks/useUpdateNotifications";
import useGetImageOccurrence from "hooks/useGetImageOccurrence";
import { UseGetXMLFile } from "hooks/useGetXML";

import { useApi } from "ctx/ApiContext";
import { useCard } from "ctx/CardRasterContext";
import { useUpdate } from "ctx/UpdateContext";

// assets
import anexoSVG from "assets/icons/anexo.svg";
import engineSVG from "assets/icons/engine.svg";
import arrival from "assets/icons/arrival.svg";

// interface

// styles
import "./index.scss";
import { db, storage } from "database/FirebaseConfig";
import { Chat } from "components/Chat";
import firebase from "firebase";
import TypeIconCard from "utils/TypeIconCard";
import { useAuth } from "ctx/AuthContext";
import { Links } from "utils/template";

const PopupEventImage = styled(Popup)`
  &-content {
    width: auto;
    padding: 6px;
    border: none;
    border-radius: 6px;
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
`;
const PopupImage = styled(Popup)`
  &-content {
    width: auto;
    max-height: 650px;
    padding: 6px;
    border: none;
    border-radius: 6px;
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
`;

type InfoCardsProps = {
  handleClick: (isCliked: boolean) => void;
  closeIcon?: any;
  openButton?: any;
  isOpenOccurence?: any;
  cardProps: any;
  closeFatherPopup?: any;
};

type ImgsNameProps = {
  url: string;
  name: string;
  info: string;
  type: string;
};

type FirebaseTimestamp = {
  seconds: number,
  nanoseconds: number,
}

type DocumentEventsType = {
  uid: string;
  createdAt: FirebaseTimestamp;
  payload?: {
    imageUrl: string,
    name: string;
    ocurrenceId: string;
  }[];
  status: number;
  user: {
    id: string;
    name: string;
    role: string;
  }
}

export function InfoCards({
  handleClick,
  cardProps,
  closeIcon,
  isOpenOccurence,
  openButton,
  closeFatherPopup,
}: InfoCardsProps) {
  const { getFinish, getNotifications, allOcc, getOccurrences } = useApi();
  const [driverId, setDriverId] = useState('')
  const { refreshPage } = useUpdate();
  const { userInfo } = useAuth();

  const alert = useAlert();
  const cardStatus = cardProps.status;
  const company = Cookies.get("company")!;


  useEffect(() => { //Load user id from references
    if (cardProps.intoRomaneio === false) return;
    async function loadUserInfo() {
      const userDocument = (await db.collection('waybills').where('general_data', '==', cardProps.currentRomaneio).get()).docs[0].data().document
      const userSnapshot = (await db.collection('users').where('document', '==', userDocument).get()).docs[0];
      const userData = userSnapshot && { ...userSnapshot.data(), uid: userSnapshot.id };
      setDriverId(userData?.uid);
    }
    loadUserInfo();
  }, [])


  const origin = cardProps.type === 2 ?
    cardProps?.origin?.address + ', ' + cardProps?.origin?.citie + '/' + cardProps?.origin?.state
    :
    cardProps?.destiny?.addressDestiny + ', ' + cardProps?.destiny?.citieDestiny + '/' + cardProps?.destiny?.stateDestiny;

  const destiny = cardProps.type === 2 ?
    cardProps?.destiny?.addressDestiny + ', ' + cardProps?.destiny?.citieDestiny + '/' + cardProps?.destiny?.stateDestiny
    :
    cardProps?.origin?.address + ', ' + cardProps?.origin?.citie + '/' + cardProps?.origin?.state;

  const finish = getFinish(cardProps);
  const notification: any = getNotifications(cardProps.uid!);

  const arrivalTime = timestampToDate(cardProps.timeToArrival);

  const imagesOccurrences = useGetImageOccurrence();
  const [occurences, setOcurrences] = useState<any>(getOccurrences(cardProps.uid!));
  // const occurences = getOccurrences(cardProps.uid!) as any;
  const [imgsName, setImgsName] = useState<ImgsNameProps[]>([]);
  const [open, setOpen] = useState(false);
  const [imgsURL, setImgsURL] = useState<any>([]);
  const [occurrenceImages, setOccurrenceImages] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [imageTab, setImageTab] = useState<any>([]);
  const [xmlURL, setXmlURL] = useState<string>("");
  const [documentOccurrences, setDocumentOccurrences] = useState([]);
  const [openedOcurrence, setOpenedOcurrence] = useState(false);
  const [docEvents, setDocEvents] = useState<DocumentEventsType[]>([]);

  const colorTheme = Cookies.get("colorTheme");

  const name = cardProps?.client?.name;
  const cpfCnpj = cardProps?.client?.cpf_cnpj;
  const fone = cardProps?.client?.fone;

  const closeModal = () => setIsOpen(false);

  const handleClosePopup = () => {
    // changeOpenInfo();
    setOpen(false);
    handleClick(false);
    if (!closeFatherPopup) return;
    closeFatherPopup();
  };

  function handleFinish() {
    UpdateCardIntoDocument(cardProps, userInfo);
    handleClosePopup();
    refreshPage();
  }
  const path = company + "/notifications/occurrences/" + cardProps.uid;
  async function handleEvent(type: string) {
    if (notification) await UpdateNotifications(notification.uid, type);
    if (type === 'finish') return;
    const status = type === 'delivery' ? 5 : type === 'return' ? 7 : 4;
    const data = {
      createdAt: firebase.firestore.Timestamp.now(),
      status,
      user: {
        role: 'manager',
        name: userInfo?.name,
        id: userInfo?.uid,
      }
    }
    await db.collection('documents').doc(cardProps.uid).collection('documentEvents').add(data)
    refreshPage();
  }

  function downloadDocument() {
    const newName = cardProps.uid + ".xml";
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      var blob = xhr.response;
      const donwloadURL = window.URL.createObjectURL(new Blob([blob]));
      //Creating a 'a' element that have the propiertys
      const link = document.createElement("a");
      link.href = donwloadURL;
      link.setAttribute("download", `${newName}`);

      //Start the download automatically
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    };
    xhr.open('GET', xmlURL);
    xhr.send();
  }


  useEffect(() => {
    const interval = setInterval(() => {
      if (occurences.length === 0) return;
      setOcurrences(getOccurrences(cardProps.uid!));
    }, 1000);

    return () => {
      clearInterval(interval);
    }
  }, [])

  useEffect(() => {
    if (occurences.length === 0 && finish.length === 0) return;

    if (finish.length > 0) setImgsURL([{ arrayOfImages: [...finish[0].finish], type: 'finalized' }]);

    imagesOccurrences.getImages({
      path,
      imageTab,
      setImageTab,
      onComplete: (imgs: any) => {
        const imgsFormatted = imgs.map((img: any) => ({
          date: undefined,
          image: img,
          type: 'occurrence'
        })).reverse();
        setOccurrenceImages([...imgsFormatted]);
        setImgsURL([...imgsFormatted, ...imgsURL]);
      },
    });
  }, [occurences]);

  useEffect(() => {
    const pathXML = company + "/XML";
    UseGetXMLFile(pathXML, cardProps.uid ?? cardProps.id, (url: string) => {
      setXmlURL(url);
    });
  }, [])

  useEffect(() => {
    if (notification !== undefined) {
      var docs: any = [];
      var references: any = [];
      let occurrencesAux = [] as any;

      if (occurences[0]?.occurrences?.length > 0) {
        occurences[0].occurrences.forEach((occurrence: any, index: number) => {
          references.push(occurrence._delegate.path.split('/')[1]);
          allOcc.forEach((occ: any) => {
            if (occ.uid === references[index]) {
              docs.push(occ);
            }
          })
        })

        const docsReverse = docs.reverse();
        docs = [...docsReverse];

        const imgsNameFormatted = docs.map((doc: any) => ({
          url: '',
          name: doc.name,
          info: doc.info,
          type: 'occurrence',
          images: doc.images,
        }));
        occurrencesAux = [...imgsNameFormatted];

        let i = 0;

        occurrencesAux = [occurrencesAux.map((occurrence: any) => {
          i++
          if (!occurrence.images) {
            return occurrence
          } else {

            if (!occurrenceImages[i - 1]) return occurrence;
            const occurrenceURL = occurrenceImages[i - 1].image;
            return {
              url: occurrenceURL,
              name: occurrence.name,
              info: occurrence.info,
              type: 'occurrence',
            }
          }
        })[occurrencesAux.length - 1]]
      };

      if (imgsURL.length !== 0) {
        imgsURL.forEach((img: any, index: number) => {
          if (img.type === 'finalized') {
            img.arrayOfImages.forEach((eachImage: any) => {
              const dateFormated = eachImage?.date !== undefined ? timestampToDate(eachImage.date.seconds) : null
              const imageTitle = eachImage.image?.split('title%3D')[1]?.split('.')[0]?.replace(/_/g, ' ');
              const format = {
                url: eachImage.image,
                name: `${imageTitle ?? 'Finalizado'}`,
                info: `Finalizado em ${dateFormated}`,
                type: 'finalized',
              }
              occurrencesAux.push(format);
            })
          }
        });
      }
      if (
        occurrencesAux.length > 0
      ) {
        setImgsName([...occurrencesAux]);
      }
    }

    return () => {
      imagesOccurrences.setLoginActivities(false);
    };
  }, [imgsURL, occurences, occurrenceImages]);

  useEffect(() => {
    if (!cardProps.intoRomaneio) return;
    const unsubscribe = db.collection('documents').doc(cardProps.uid).collection('documentEvents').orderBy('createdAt', 'asc').onSnapshot(snap => {
      const allEvents = snap.docs.map(doc => ({
        uid: doc.id,
        ...doc.data(),
      } as DocumentEventsType))
      setDocEvents(allEvents);
      const lastEvent = allEvents[allEvents.length - 1];
      const isOcurrenceOpened = lastEvent?.status === 3;
      setOpenedOcurrence(isOcurrenceOpened);
    })
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto'
      unsubscribe();
    }
  }, []);

  return (
    <div className="info">
      {closeIcon !== false && (
        <AiOutlineCloseCircle
          style={{
            position: "absolute",
            top: "8px",
            right: "6px",
            cursor: "pointer",
          }}
          size={22}
          color="white"
          onClick={() => {
            handleClosePopup();
          }}
        />
      )}

      <header style={{ background: `var(--${colorTheme})` }}>
        <span>Informações</span>
      </header>

      <main>
        <div className="infoHeader">
          <div style={{ display: 'flex', gap: 20 }}>
            <span
              className={
                cardProps.status === 4
                  ? "finish"
                  : cardProps.status === 3 || cardProps.status === 7
                    ? "warning"
                    : cardProps.type === 1
                      ? "collect"
                      : "delivery"
              }
            >
              {cardProps.type === 1 ? "coleta" : "entrega"}
            </span>
            <TypeIconCard typeImage={cardProps.status} />
          </div>

          <div className="anexo">
            {xmlURL && (
              <>
                <img src={anexoSVG} alt="anexo" />
                <a onClick={downloadDocument}>visualizar anexos da entrega</a>
              </>
            )}
          </div>
        </div>

        <div className="infoAndChat">
          <aside>
            <div>
              {cardProps.oldDocumentLocation && (
                <p
                  style={{
                    textAlign: "end",
                    width: 180,
                    color: "var(--warning)",
                  }}
                >
                  Registro anterior: {cardProps.oldDocumentLocation}
                </p>
              )}
            </div>

            <div className="block-header" style={{
              alignItems: 'flex-start',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              gap: 10,
            }}>
              <span>{cardProps.nameDocument}</span>
              <p style={{
                color: 'var(--text)',
                fontSize: 12,
                marginBottom: 0,
              }}>
                Cód. {cardProps.cod}
              </p>
            </div>

            <div className="infoAndChatMain">
              <div className="info">
                <div className="blockInfoCard">
                  <span>Destino: </span>
                  <p style={{ textAlign: "justify" }}>{destiny}</p>
                </div>

                <div className="blockInfoCard">
                  <span>Cliente: </span>
                  <p style={{ margin: 0 }}>{name}</p>
                  <p style={{ margin: 0 }}>{cpfCnpj}</p>
                  <p style={{ margin: 0 }}>{fone}</p>
                </div>

                <div className="blockInfoCard">
                  <span>Origem de produto: </span>
                  <p style={{ textAlign: "justify" }}>{origin}</p>
                </div>

                <div className="blockInfoCard">
                  <span>Cliente: </span>
                  <p>{cardProps?.clientDestiny}</p>
                </div>

                <div className="blockInfoCard">
                  <span>Entrega prevista: </span>
                  <label>
                    <img
                      src={arrival}
                      alt="chegada"
                      style={{ marginRight: 12 }}
                    />
                    {arrivalTime}
                  </label>
                </div>
              </div>

            </div>
          </aside>

          <div
            className="chatImages"
            style={
              imgsName.length > 0 || finish.length > 0
                ? { overflowY: "scroll", maxHeight: 400 }
                : {}
            }
          >
            {/* <div
              className={
                (notification === undefined || imgsName.length <= 0) ? "chat chatEmpty" : "chat"
              }
            >
              {(notification === undefined || imgsName.length <= 0) && (
                <div className="blockDateImage">
                  <span style={{ paddingLeft: "8px" }}>
                    Demanda sem registro!
                  </span>
                </div>
              )}
            </div> */}
            <div className="imagesOccurrences chat">
              {imgsName.length > 0 && (
                <>
                  <legend style={{ paddingLeft: 15, fontWeight: "bold", fontSize: 18 }}>
                    Ocorrências:{" "}
                  </legend>
                  {imgsName.length > 0 && imgsName.map((item: ImgsNameProps, index: any) => {
                    if (item.name === undefined) {
                      return;
                    }
                    return (
                      <>
                        <div
                          key={item.url}
                          className="containerImagesOccurrences"
                        >
                          <span>{item.name}</span>
                          {item.url !== "" && item.url !== undefined && (
                            <OpenImages image={item.url} />
                          )}
                          <span>{item.info}</span>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
              {docEvents.length > 0 ? (
                <div style={{ height: '100vh' }}>
                  <legend style={{ paddingLeft: 15, fontWeight: "bold", fontSize: 18 }}>
                    Eventos:
                  </legend>
                  <Timeline style={{ marginLeft: 10 }} >
                    {docEvents.map(event => {
                      const eventTitle = event.status === 5 ? 'Em trânsito'
                        :
                        event.status === 3 ? 'Ocorrência'
                          :
                          event.status === 7 ?
                            'Retornar mercadoria'
                            :
                            'Finalizado';
                      const eventDate = `${timestampToDate(event.createdAt)} - ${secondsToTime(event.createdAt.seconds)}`;
                      const circleColor = event.status === 5 ? 'var(--secondary)' : event.status === 3 || event.status === 7 ? 'var(--notification)' : 'var(--traveling)'
                      return (
                        <Timeline.Item className="timeLineItem" color={circleColor}>
                          <h3>{eventTitle}</h3>
                          <p>{eventDate}</p>
                          {!!event?.payload && event?.payload?.length > 0 ?
                            event.payload.map(payload => {
                              const nameFormatted = payload.name === 'client' ?
                                'Foto do cliente'
                                :
                                payload.name === 'stub' ?
                                  'Foto do canhoto'
                                  : payload.name === 'package' ?
                                    'Foto da mercadoria'
                                    : payload.name;

                              return (
                                <>
                                  <span>{nameFormatted}</span>
                                  {payload.imageUrl !== '' &&
                                    <OpenEventImages image={payload.imageUrl} />}
                                </>
                              )
                            })
                            :
                            <p>{event.user.name}</p>
                          }
                          { }
                        </Timeline.Item>
                      )
                    })}
                  </Timeline>
                </div>
              )
                :
                <div style={{ height: '100vh', display: 'flex' }}>
                  <p style={{ color: 'var(--secondary)', margin: 'auto' }}>
                    Ainda não foram criados eventos
                  </p>
                </div>
              }
            </div>
          </div>

          <Chat companyId={company} driverId={driverId} cardId={cardProps.uid} cardStatus={cardStatus} />

        </div>
        {!isOpenOccurence && (
          <div className="buttonBlock">
            <label>
              {cardProps.status !== 4 &&
                <>
                  <img src={engineSVG} alt="alterar" />
                  <CreateDocuments
                    Target={<span>Alterar</span>}
                    title="Alterar documento"
                    cardProps={cardProps}
                    update={true}
                    closeModalInfoCard={closeModal}
                  />
                </>
              }
            </label>
            {cardProps.status !== 4 && cardProps.status !== 7 && cardProps.status !== 3 ? (
              <button
                id="finished"
                onClick={() => {
                  if (closeFatherPopup) closeFatherPopup();
                  handleClosePopup();
                  confirmAlert({
                    message: "Deseja finalizar essa demanda do romaneio?",
                    buttons: [
                      {
                        label: "Cancelar",
                        onClick: () => "Cancelar",
                      },
                      {
                        label: "Sim",
                        onClick: () => {
                          handleFinish();
                          handleEvent("finish");
                          alert.success("Demanda finalizada!");
                        },
                      },
                    ],
                    overlayClassName: "confirmAlert",
                  });
                }}
              >
                Finalizar entrega
              </button>
            ) : cardProps.status === 4 ?
              null
              :
              (
                <div className="buttonOccurrences">
                  <button
                    onClick={() => {
                      async function Wait() {
                        if (!!notification) await UpdateAllOccurrencesToCheck(notification.uid);
                        await UpdateCardsToReturnDelivery(
                          cardProps.uid
                        );
                        handleEvent("return");
                        alert.success("Ocorrência verificada!");
                      }
                      Wait();
                      handleClosePopup();
                    }}
                  >
                    Devolver
                  </button>
                  <button
                    onClick={() => {
                      async function Wait() {
                        await RemoveStateWarningInCard(
                          cardProps.uid
                        );
                        handleEvent("delivery");
                        alert.success("Ocorrência verificada!");
                      }
                      Wait();
                      handleClosePopup();
                    }}
                  >
                    Entregar
                  </button>
                </div>
              )
            }
          </div>
        )}
      </main>

    </div>
  );
}

const OpenImages = ({ image }: any) => {
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  async function loadUrlDownload() {
    const newImageName = image.replace(
      Links.downloadURLPreFix,
      ""
    );
    const httpsReference = storage.refFromURL(image);
    const url = await httpsReference.getDownloadURL();

    const xhr = new XMLHttpRequest();

    xhr.responseType = "blob";

    xhr.onload = () => {
      const blob = xhr.response;
      const donwloadURL = window.URL.createObjectURL(new Blob([blob]));

      //Creating a 'a' element that have the propiertys
      const link = document.createElement("a");
      link.href = donwloadURL;
      link.setAttribute("download", `${newImageName}.jpg`);

      //Start the download automatically
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    };
    xhr.open("GET", url);
    xhr.send();
  }

  return (
    <PopupImage
      trigger={
        <div>
          <button
            type="button"
            className="button"
            id="buttonOpenPopup"
            style={{ width: "100%" }}
            onClick={() => setIsModalImageOpen(true)}
          >
            <img
              src={image}
              alt="finish"
              style={{
                cursor: "pointer",
                border: "4px solid var(--secondary)",
              }}
            />
          </button>
        </div>
      }
      modal
      className="mypopupNotificattion"
      onClose={() => setIsModalImageOpen(false)}
      open={isModalImageOpen}
      closeOnDocumentClick={false}
      keepTooltipInside=".tooltipBoundaryNotification"
      lockScroll
    >
      <AiOutlineCloseCircle
        size={18}
        style={{
          position: "absolute",
          right: "4px",
          top: "4px",
          cursor: "pointer",
          marginBottom: 10,
        }}
        onClick={() => setIsModalImageOpen(false)}
      />
      <div className="imageContainer">
        <img
          src={image}
          alt="finish"
          style={{
            marginTop: 16,
            maxWidth: 920,
            maxHeight: 615,
            borderRadius: 8,
            paddingTop: 4,
            paddingBottom: 20,
          }}
        />

        <div
          className="imageDownloadContainer"
          onClick={(event) => {
            loadUrlDownload();
            event.preventDefault();
          }}
        >
          <h3>Baixar Imagem</h3>
          <AiOutlineDownload fontSize={20} />
        </div>
      </div>
    </PopupImage>
  );
};

const OpenEventImages = ({ image }: any) => {
  const [isModalImageOpen, setIsModalImageOpen] = useState(false);

  async function loadUrlDownload() {
    const newImageName = image.replace(
      Links.downloadURLPreFix,
      ""
    );
    const httpsReference = storage.refFromURL(image);
    const url = await httpsReference.getDownloadURL();

    const xhr = new XMLHttpRequest();

    xhr.responseType = "blob";

    xhr.onload = () => {
      const blob = xhr.response;
      const donwloadURL = window.URL.createObjectURL(new Blob([blob]));

      //Creating a 'a' element that have the propiertys
      const link = document.createElement("a");
      link.href = donwloadURL;
      link.setAttribute("download", `${newImageName}.jpg`);

      //Start the download automatically
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    };
    xhr.open("GET", url);
    xhr.send();
  }

  return (
    <PopupEventImage
      trigger={
        <div>
          <button
            type="button"
            style={{ alignSelf: 'flex-start', border: 'none', background: 'transparent' }}
            onClick={() => setIsModalImageOpen(true)}
          >
            <img
              src={image}
              alt="finish"
              style={{
                marginTop: 2,
                maxWidth: 150,
                borderRadius: 8,
                paddingTop: 4,
                paddingBottom: 20,
              }}
            />
          </button>
        </div>
      }
      modal
      onOpen={() => document.body.style.overflow = 'auto'}
      onClose={() => {
        setIsModalImageOpen(false)
        document.body.style.overflow = 'hidden'
      }}
      open={isModalImageOpen}
      closeOnDocumentClick={false}
      keepTooltipInside=".tooltipBoundaryNotification"
      lockScroll
    >
      <AiOutlineCloseCircle
        size={20}
        color='#fff'
        style={{
          position: "absolute",
          right: "8px",
          top: "8px",
          cursor: "pointer",
          marginBottom: 10,
        }}
        onClick={() => setIsModalImageOpen(false)}
      />
      <div className="imageContainer">
        <img
          src={image}
          alt="finish"
          style={{
            cursor: "pointer",
            border: "4px solid var(--secondary)",
            maxHeight: '100vh',
          }}
        />
        <div
          className="imageDownloadEventContainer"
          onClick={(event) => {
            loadUrlDownload();
            event.preventDefault();
          }}
        >
          <h3>Baixar Imagem</h3>
          <AiOutlineDownload fontSize={20} color='var(--secondary)' />
        </div>
      </div>
    </PopupEventImage>
  );
};
