// eslint-disable-next-line
import styled from "styled-components";
import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";

// components
import { Header } from "components/Header";
import { useUpdate } from "ctx/UpdateContext";
import { MainRomaneio } from "components/SectionRomaneios";

// assets
import refresh from "assets/icons/refresh.svg";

// styles
import "react-tabs/style/react-tabs.css";
import "./index.scss";
import { useAuth } from "ctx/AuthContext";
import { TypesenseDocs } from "./TypesenseDocs";
import { Colors } from "utils/template";
import { ReportTable } from "./ReportTable";

type DivProps = {
  colorTheme: string;
};

const BACKGROUNDCOLOR = Colors.secondary;

const Div = styled.div<Pick<DivProps, "colorTheme">>`
  * {
    --secondary: ${(props) => props.colorTheme};
  }
`;

function DocumentsView() {
  const { refreshPage, switchTabs, tabs } = useUpdate();
  const colorTheme = Cookies.get("colorTheme");

  const override = css`
    display: block;

    margin: 20% auto;
    border-color: red;
  `;

  const currentUser = Boolean(Cookies.get("currentUser"));

  const { userInfo } = useAuth();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const romaneioButtons = document.getElementById("btnsOnScroll");
      romaneioButtons?.classList.toggle(
        "btnsOnScrollVisible",
        window.scrollY > 250
      );
    });
  }, []);


  if (currentUser === undefined || currentUser === false) {
    return <Redirect to="/" />;
  }

  if (userInfo?.company === "") {
    return (
      <div className="sweet-loading">
        <MoonLoader color="var(--secondary)" loading={true} css={override} size={75} />
      </div>
    );
  }

  if (colorTheme === undefined) {
    document.documentElement.style.setProperty("--secondary", BACKGROUNDCOLOR);
  } else {
    document.documentElement.style.setProperty("--secondary", colorTheme);
  }

  return (
    <Div colorTheme={colorTheme || BACKGROUNDCOLOR}>
      <div className="body">
        <Header />
        <Tabs>
          <TabList>
            <Tab
              className="react-tabs__tab"
            >
              <legend>Gestão de entregas</legend>
            </Tab>
          </TabList>

          <TabPanel>
            <main>
              <div className="manualTabs">
                <div className="firstTabs">
                  <span
                    className={tabs === 1 ? "focusClick" : ""}
                    onClick={() => switchTabs(1)}
                  >
                    Documentos
                  </span>
                  <span
                    className={tabs === 2 ? "focusClick" : ""}
                    onClick={() => switchTabs(2)}
                  >
                    Romaneios
                  </span>
                  <span
                    className={tabs === 3 ? "focusClick" : ""}
                    onClick={() => switchTabs(3)}
                  >
                    Relatórios
                  </span>
                </div>

                <div className="secondTabs">
                  <img
                    src={refresh}
                    alt="Refresh"
                    onClick={() => refreshPage()}
                  />
                  <span onClick={() => refreshPage()}>Atualizar</span>
                </div>
              </div>
              <div />
              {tabs === 1 && (
                <TypesenseDocs />
              )}

              {tabs === 2 && (
                <section>
                  <div className="cardsRomaneios">
                    <MainRomaneio />
                  </div>
                </section>
              )}
              {tabs === 3 &&
                <ReportTable />
              }
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </Div>
  );
}

export default React.memo(DocumentsView);
