import { ReactNode, useState, createContext, useContext, useEffect } from "react";
import { Occurence, OccurrenteProps } from "components/Occurences";

type FinishPhotoInfos = {
  image: string;
  date: { seconds: number; nanoseconds: number; }
}

export interface Notifications {
  check: boolean;
  company: string;
  document: string;
  finish: FinishPhotoInfos[];
  occurrences: string[];
  response: string;
  uid: string;
}

export interface MessagesI {
  uid: string;
  message: string;
  createdAt: {
    seconds: number,
    nanoseconds: number,
  };
  user: {
    id: string;
    name: string;
  }
}

export interface LastMessagesI {
  createdAt: any;
  id: string;
  message: string;
}

type ApiContextProps = {
  drivers: any;
  driversRM: any;
  allOcc: any;
  driversNoDriving: any;
  dataRomaneios: any;
  allFinish: any;
  allOccurence: any;
  updateR: any;
  notifications: any;
  totalNotification: number;
  refreshDocuments: boolean;
  allNotifications: Notifications[];
  allLastMessages: LastMessagesI[];
  chatIsOpen: boolean;



  loadDrivers: (d: any) => void;
  loadNotifications: (notifications: any) => void;
  loadDriversRM: (driversRM: any) => void;
  getFinish: (card: any) => any;
  getDriver: (cpf: string) => any;
  loadRomaneio: (rmeio: any) => any;
  loadFinish: (f: any) => any;
  loadOccurrences: (o: any) => any;
  loadOccurrencesDescriptions: (occ: any) => any;
  updateRomaneio: () => void;
  getNotifications: (uid: string) => void;
  getOccurrences: (uid: string) => void;
  loadTotalNotifications: (tam: number) => void;
  setRefreshDocuments: (refresh: boolean) => void;
  refreshTypesensDocuments: () => void;
  setAllNotifications: (doc: any) => void;
  loadLastMessages: (lastMessages: LastMessagesI[]) => void;
  setChatIsOpen: (s: boolean) => void;
};

type ApiProviderProps = {
  children: ReactNode;
};

export const ApiContext = createContext({} as ApiContextProps);

export function ApiProvider({ children }: ApiProviderProps) {
  const [drivers, setDrivers] = useState<any>([]);
  const [driversRM, setDriversRM] = useState<any>([]);
  const [driversNoDriving, setDriversNoDriving] = useState<any>([]);
  const [dataRomaneios, setDataRomaneios] = useState<any>([]);
  const [allLastMessages, setAllLastMessages] = useState<LastMessagesI[]>([]);
  const [chatIsOpen, setChatIsOpen] = useState(false);

  const [allFinish, setAllFinish] = useState<any>([]);
  const [allOccurence, setAllOccurence] = useState<any>([]);

  const [totalNotification, setTotalNotification] = useState<any>(0);
  const [notifications, setNotifications] = useState<any>("");

  const [updateR, setUpdateR] = useState<any>(false);
  const updateRomaneio = () => setUpdateR(!updateR);

  const [refreshDocuments, setRefreshDocuments] = useState(false);

  const [allOcc, setAllOcc] = useState<OccurrenteProps[]>();
  const [allNotifications, setAllNotifications] = useState<Notifications[]>([] as Notifications[])

  function getFinish(card: any) {
    var finish: any = [];

    for (let i = 0; i < allFinish.length; i++) {
      if (card.uid === allFinish[i].document) {
        finish.push(allFinish[i]);
        break;
      }
    }
    return finish;
  }

  function loadOccurrencesDescriptions(occ: any) {
    setAllOcc(occ);
  }

  function loadFinish(f: any) {
    setAllFinish(f);
  }

  function refreshTypesensDocuments() {
    setRefreshDocuments(true);
    setTimeout(() => { setRefreshDocuments(false) }, 1000);
    console.log('refreshTypesensDocuments');

  }

  function loadOccurrences(o: any) {
    setAllOccurence(o);
  }

  function loadRomaneio(rmeio: any) {
    setDataRomaneios(rmeio);
  }

  function loadDrivers(d: any) {
    var driversNotDriving: any = [];

    for (let i = 0; i < d.length; i++) {
      if (d[i].status === 0) {
        driversNotDriving.push(d[i]);
      }
    }
    setDrivers(d);
    setDriversNoDriving(driversNotDriving);
  }

  function loadTotalNotifications(tam: number) {
    setTotalNotification(tam);
  }

  // driver of raster MOB
  function loadDriversRM(driversRM: any) {
    setDriversRM(driversRM);
  }

  function getDriver(document: string) {
    var driver: any = [];

    for (let i = 0; i < drivers.length; i++) {
      if (drivers[i].cpf === document) {
        driver.push(drivers[i]);
        break;
      }
    }
    return driver;
  }

  function loadLastMessages(lastMessages: LastMessagesI[]) {
    setAllLastMessages(lastMessages)
  }

  function loadNotifications(notifications: any) {
    setNotifications(notifications);
  }

  function getNotifications(uid: string) {
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].document === uid) {
        return notifications[i];
      }
    }
  }
  function getOccurrences(uid: string) {
    return allOccurence.filter((occurrence: any) => occurrence.document === uid);
  }

  return (
    <ApiContext.Provider
      value={{
        updateRomaneio,
        updateR,
        getFinish,
        allFinish,
        allNotifications,
        allOccurence,
        loadFinish,
        loadOccurrences,
        drivers,
        dataRomaneios,
        driversNoDriving,
        loadDrivers,
        getDriver,
        setAllNotifications,
        loadRomaneio,
        allOcc,
        loadOccurrencesDescriptions,
        totalNotification,
        loadTotalNotifications,
        driversRM,
        loadDriversRM,
        loadNotifications,
        notifications,
        getNotifications,
        refreshDocuments,
        setRefreshDocuments,
        refreshTypesensDocuments,
        getOccurrences,
        allLastMessages,
        loadLastMessages,
        setChatIsOpen,
        chatIsOpen,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export const useApi = () => {
  return useContext(ApiContext);
};
