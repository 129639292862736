import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/messaging";

const configExtratta = {
  apiKey: "AIzaSyAoZ3oyCzxY6DI4VIGc-GcgeCsYCuKETK8",
  authDomain: "extratta-app.firebaseapp.com",
  projectId: "extratta-app",
  storageBucket: "extratta-app.appspot.com",
  messagingSenderId: "466586399172",
  appId: "1:466586399172:web:582d0aaa2c2f9fa8233491",
};

const configHomolog = {
  apiKey: "AIzaSyAoZ3oyCzxY6DI4VIGc-GcgeCsYCuKETK8",
  authDomain: "extratta-app.firebaseapp.com",
  projectId: "extratta-app",
  storageBucket: "extratta-app.appspot.com",
  messagingSenderId: "466586399172",
  appId: "1:466586399172:web:582d0aaa2c2f9fa8233491",
};

const app = firebase.initializeApp(configExtratta);
const messaging = firebase.messaging(app);

const db = firebase.firestore(app);
const dbForms = db;

const storage = firebase.storage();

export { db, dbForms, messaging, storage, firebase as default };