import { memo, useEffect, useRef, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { AiOutlineCloseCircle } from "react-icons/ai";

// components
import TypeIconCard from "utils/TypeIconCard";
import secondsToDate from "utils/timestampToDate";

import { InfoCards } from "components/InfoCards";

import { useCard } from "ctx/CardRasterContext";
import { useUpdate } from "ctx/UpdateContext";

import { CancelCard } from "hooks/useUpdateCard";

// assets
import checkbox from "assets/icons/checkTrue.svg";
import exit from "assets/icons/exit.svg";
import arrival from "assets/icons/arrival.svg";

// interface

// styles
import "./index.scss";
import "rc-slider/assets/index.css";
import { useApi } from "ctx/ApiContext";

type CardProps = {
  checkBox?: boolean;
  isRomaneio?: boolean;
  card: any;
  isIntoRomaneio?: boolean;
  removeDocumentIntoRomaneio?: (card: any) => void;
  handleUpdateCardsIntoRomaneio?: (
    card: any,
    addOrRemove: boolean
  ) => void;
  props?: any;
  isOnDocumentView?: boolean;
};




const StyledPopup = styled(Popup)`
  &-content {
    min-width: 1200px;
    padding: 0px;
    border: none;
    border-radius: 20px;
    @media (max-width: 560px) {
      width: 90%;
      display: block;
    }
  }
`;

export const CardRaster = ({
  checkBox,
  card,
  isIntoRomaneio,
  handleUpdateCardsIntoRomaneio,
  removeDocumentIntoRomaneio,
  isOnDocumentView
}: CardProps) => {

  const alert = useAlert();
  const { setIds } = useCard();
  const [isMarked, setIsMarked] = useState(isIntoRomaneio || false);
  const [isDisabledChangeCard, setIsDisabledChangeCard] = useState(false);
  const { refreshPage } = useUpdate();
  const closeFatherPopup = () => setIsDisabledChangeCard(false);
  const { changeOpenInfo } = useCard();


  const renderCard = isOnDocumentView ? (card.status === 1 || card.status === 4) : true;

  var styleClass = "";
  if (card.status === 4) {
    // transfer
    styleClass = "headerCard headerCardFinish";
  } else if (card.type === 1) {
    // delivery
    styleClass = "headerCard headerCardCollect";
  } else if (card.type === 2) {
    // transfer
    styleClass = "headerCard headerCardDelivery";
  } else if (card.status === 3) {
    // collect
    styleClass = "headerCard headerCardWarning";
  } else if (card.type === 6) {
    // collect
    styleClass = "headerCard headerCardTransfer";
  } else if (card.type === 7) {
    // collect
    styleClass = "headerCard headerCardWarning";
  }

  const exitTime = secondsToDate(card.timeToExitSender);
  const arrivalTime = secondsToDate(card.timeToArrival);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    changeOpenInfo();
  };

  // const origin = card.address + ", " + card.citie + "/" + card.state;
  // const destiny = card.addressDestiny + ", " + card.citieDestiny + "/" + card.stateDestiny;

  const origin = card.type === 2 ?
    card?.origin?.address + ', ' + card?.origin?.citie + '/' + card?.origin?.state
    :
    card?.destiny?.addressDestiny + ', ' + card?.destiny?.citieDestiny + '/' + card?.destiny?.stateDestiny;

  const destiny = card.type === 2 ?
    card?.destiny?.addressDestiny + ', ' + card?.destiny?.citieDestiny + '/' + card?.destiny?.stateDestiny
    :
    card?.origin?.address + ', ' + card?.origin?.citie + '/' + card?.origin?.state;


  const titleCard = card.type === 1 ? "coleta" : "entrega";

  function handleClickIntoInfoCard() {
    setOpen(false);
  }

  function handleCancel() {
    CancelCard(card);
  }


  function handleCheckBoxCardIntoRomaneio(addOrRemove: boolean) {
    if (handleUpdateCardsIntoRomaneio !== undefined) {
      handleUpdateCardsIntoRomaneio(card, addOrRemove);
    }
  }

  useEffect(() => { // Lock scroll on popups open
    open || isDisabledChangeCard ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [open, isDisabledChangeCard]);

  return (
    <>
      {renderCard ?
        <div
          className={
            card.status === 3 || card.status === 7
              ? "mainCard mainCardOccurrence"
              : "mainCard mainCardDefault"
          }
        >
          <div
            className={
              card.status === 3 || card.status === 7
                ? "headerCard headerCardWarning"
                : styleClass
            }
          >
            <div className="blockRight">
              <label>{titleCard}</label>
              <span>
                {card.nameDocument === ""
                  ? card?.client?.name.split(" ")[0]
                  : card?.nameDocument
                }
              </span>
            </div>
            <div className="blockLeft tooltipBoundary">
              {checkBox && !isIntoRomaneio && card.status !== 4 && (
                <input
                  type="checkbox"
                  onClick={() => {
                    setIds(card, true);
                    handleCheckBoxCardIntoRomaneio(true);
                    setIsMarked(true);
                  }}
                />
              )}
              <Popup
                trigger={
                  <button type="button" className="button" id="buttonOpenPopup">
                    <HiDotsVertical
                      size={25}
                      style={{ cursor: "pointer" }}
                      color="#fff"
                      onClick={() => {
                        setIsDisabledChangeCard(true);
                      }}
                    />
                  </button>
                }
                className="mypopup"
                position={["top center", "bottom right", "bottom left"]}
                open={isDisabledChangeCard}
                onClose={() => setIsDisabledChangeCard(false)}
                closeOnDocumentClick={!open}
                keepTooltipInside=".tooltipBoundary"
              >
                <div className="changeCard" style={{ zIndex: 100 }}>
                  <AiOutlineCloseCircle
                    size={14}
                    onClick={() => setIsDisabledChangeCard(false)}
                    style={{ position: "absolute", right: "2px", top: "2px" }}
                  />

                  <StyledPopup
                    trigger={
                      <div>
                        <span
                          className="dootsMenu"
                          style={{ marginTop: 6, marginBottom: 6 }}
                          onClick={() => setOpen(true)}
                        >
                          Informações
                        </span>
                      </div>
                    }
                    open={open}
                    onOpen={changeOpenInfo}
                    onClose={closeModal}
                    closeOnDocumentClick={false}
                    modal
                    position="top center"
                  >
                    <InfoCards
                      handleClick={handleClickIntoInfoCard}
                      cardProps={card}
                      closeFatherPopup={closeFatherPopup}
                    />
                  </StyledPopup>
                  {removeDocumentIntoRomaneio && card.status !== 4 ? (
                    <span
                      className="dootsMenu"
                      onClick={() => {
                        setIsDisabledChangeCard(false)
                        confirmAlert({
                          message: "Deseja remover essa demanda do romaneio ?",
                          buttons: [
                            {
                              label: "Cancelar",
                              onClick: () => "Cancelar",
                            },
                            {
                              label: "Sim",
                              onClick: () => {
                                removeDocumentIntoRomaneio(card);
                                setIsDisabledChangeCard(false);
                                refreshPage();
                                alert.success("Documento removido!");
                              },
                            },
                          ],
                          overlayClassName: "confirmAlert",
                        });
                      }}
                    >
                      Remover
                    </span>
                  ) : (
                    !isIntoRomaneio && card.status !== 4 && (
                      <span
                        className="dootsMenu"
                        onClick={() => {
                          confirmAlert({
                            message: "Deseja arquivar essa demanda ?",
                            buttons: [
                              {
                                label: "Cancelar",
                                onClick: () => "Cancelar",
                              },
                              {
                                label: "Sim",
                                onClick: () => {
                                  let cardIntoRomaneio = card.intoRomaneio
                                  if (!cardIntoRomaneio) {
                                    handleCancel();
                                    alert.success("Documento removido com sucesso!");
                                  } else {
                                    alert.error("Documento não foi removido pois está ligado a um romaneio")
                                  }
                                },
                              },
                            ],
                            overlayClassName: "confirmAlert",
                          });
                        }}
                      >
                        Remover
                      </span>
                    )
                  )}
                </div>
              </Popup>

              {isIntoRomaneio ? (
                <img style={{ display: 'none' }} src={checkbox} alt="checkbox disable" />
              ) : (
                isMarked && (
                  <img
                    src={checkbox}
                    alt="checkbox true"
                    onClick={() => {
                      setIsMarked(!isMarked);
                      setIds(card, false);
                      handleCheckBoxCardIntoRomaneio(false);
                    }}
                  />
                )
              )}
            </div>
          </div>

          <div className="codCollect">
            <p>
              Cód.{" "}
              {card.cod.length > 5 ? card.cod.substring(0, 5) + "..." : card.cod}
            </p>
            <div className="statusCard">
              <TypeIconCard typeImage={card.status} />
            </div>
          </div>

          <div className="blockInfo">
            <span>Cliente:</span>
            <p>{card?.client?.name}</p>
          </div>
          <div className="blockInfo">
            <span>Origem:</span>
            <p>{origin}</p>
          </div>
          <div className="blockInfo">
            <span>Destino:</span>
            <p>{destiny}</p>
          </div>
          <div className="blockInfoTime">
            <span>
              <img src={exit} alt="exit" />
              {exitTime}
            </span>
            <span>
              <img src={arrival} alt="exit" />
              {arrivalTime}
            </span>
          </div>
        </div>
        :
        null
      }
    </>
  );
}

// export const CardRaster = memo(CardRasterComponent, (prevProps, nextProps) => (
//   JSON.stringify(prevProps) === JSON.stringify(nextProps)
// ));