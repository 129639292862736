import { FormEvent, useEffect, useState } from "react";
import { AiOutlineCloseCircle, AiOutlineSend } from "react-icons/ai";
import styled from "styled-components";
import { useAlert } from "react-alert";
import Popup from "reactjs-popup";
import { Companies } from "Page/PainelCompanieUsers";
import firebase from "database/FirebaseConfig";

import { Checkbox } from "components/Checkbox";
import { ResetPassword } from "components/ResetPassword";
import { useUsers } from "hooks/useGetUsers";
import "./index.scss";
import { Account } from "components/Account";
import MaskCPFCNPJ from "utils/MaskCpfCnpj";
import { useAuth } from "ctx/AuthContext";
import { Colors } from "utils/template";

type UsersAccountProps = {
  // users: any;
  item: Companies;
  closeModalUsersFromHeader?: () => void;
  children: React.ReactNode;
  themeHex?: string;
  allCompanies?: any
};

type FormartUsers = {
  user: string;
  status: boolean;
};

export type UserProps = {
  name: string;
  document: string;
  role: string;
  driverAvailable: number;
  access: {
    delivery: boolean;
    jor: boolean;
    position: boolean;
  };
  company: string;
  password: string;
  uid: string;
  email?: string;
  phoneNumber?: string;
  acceptedAt: {
    nanoseconds: number;
    seconds: number;
  };
};

export type CompanyProps = {
  access: {
    delivery: boolean;
    jor: boolean;
    position: boolean;
  };
  id: number;
  name: string;
  users: any;
};

const StyledPopupUsers = styled(Popup)`
  &-content {
    padding: 0;
    border-radius: 8px;
    width: 1400px;
    border: none;
  }
`;

type SelectedRoleOptions = 'driver' | 'manager';

const ManagerAccounts = ({
  item,
  closeModalUsersFromHeader,
  themeHex,
  allCompanies,
  ...children
}: UsersAccountProps) => {
  const alert = useAlert();
  const db = firebase.firestore();
  const [name, setName] = useState("");
  const role = "manager";
  const [access, setAccess] = useState({
    delivery: 1,
    jor: 1,
    position: 1,
  });
  const { userInfo } = useAuth();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [document, setDocument] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [dataUsers, setDataUsers] = useState<UserProps[]>([]);
  const [company, setCompany] = useState<any>({});
  const [companyUsers, setCompanyUsers] = useState<any>({});
  const [users, setUsers] = useState<any>([]);


  const [selectedRole, setSelectedRole] = useState<SelectedRoleOptions>('manager');
  const color = themeHex ? themeHex : Colors.secondary;

  const [openUsers, setOpenUsers] = useState(false);
  const closeModalUsers = () => {
    setOpenUsers(false);
    clearFields();
    if (closeModalUsersFromHeader) {
      closeModalUsersFromHeader();
    }
  };


  useEffect(() => {
    if (item.uid) {
      async function loadUsersCompany() {
        const companyInfo = await db
          .collection("companies")
          .doc(item.uid)
          .get();
        setCompany({ ...companyInfo.data(), uid: item.uid });
        setCompanyUsers(companyInfo?.data()?.users)
      }
      loadUsersCompany();
    }
  }, []);

  useEffect(() => {
    if (!openUsers) return;
    const unsubscribe = db.collection('users').where('company', '==', item.uid).onSnapshot(snap => {
      const usersData = snap.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      }))
      setUsers(usersData);
    })
    return unsubscribe;
  }, [openUsers])

  useEffect(() => {
    if (!users.length || !Object.keys(companyUsers).length) return;

    const userFiltered = users.filter(
      (user: any) => (user.company === item.uid || Object.keys(companyUsers).includes(MaskCPFCNPJ(user.document))) && user.role !== "superadmin" && !user.deletedAt
    );

    const driversWithoutRegister = Object.keys(companyUsers)
      .filter(userDocument => !userFiltered.find((element: any) => MaskCPFCNPJ(element.document) === MaskCPFCNPJ(userDocument)))
      .map((document: any) => ({
        document,
        role: 'driver'
      }));

    console.log(Object.keys(companyUsers), users)

    const allUsers = [...userFiltered, ...driversWithoutRegister];
    setDataUsers(allUsers);
  }, [users, allCompanies, companyUsers]);


  var data: FormartUsers[] = [];

  let props;

  for (props in users) {
    let formart = {
      user: props,
      status: users[props],
    };
    data.push(formart);
  }

  let companyUser = { ...company, uid: item.uid }

  async function handleSubmitCreateUser(event: FormEvent) {
    event.preventDefault();
    if (password !== passwordConfirm) return;
    const today = new Date();
    const documentWithoutMask = document.replaceAll(".", "").replace("-", "");

    const data = {
      accepteAt: today,
      access,
      company: item.uid,
      role,
      email,
      name: name,
      document,
    }

    const userIsInCompany = Object.keys(companyUsers).findIndex((user: string) => user === documentWithoutMask);

    if (userIsInCompany !== -1) {
      alert.error('CPF já está em uso na empresa!');
      return;
    }


    const newUserInserted = {
      ...companyUsers,
      [documentWithoutMask]: true,
    };

    try {
      if (selectedRole === 'manager') {
        const userCredentials = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);

        if (userCredentials.user?.uid!!) {
          await db.collection("users").doc(userCredentials.user!.uid).set(data);
        }
      }
      setCompanyUsers(newUserInserted);
      setCompany((prevState: any) => ({ ...prevState, users: newUserInserted }));
      await UpdateAccountInCompanies(companyUser.uid, newUserInserted);

      clearFields();

    } catch (error: any) {
      console.log(error);
      if (error.code === 'auth/email-already-in-use') {
        alert.error('Email já está em uso!');
      }
    }
  }

  async function UpdateAccountInCompanies(uid: string, newUsers: any) {
    const updates = {} as any;
    updates[newUsers.document] = true;

    db.collection("companies")
      .doc(uid)
      .update({ users: newUsers })
      .then(() => {
        console.log("Profile Successfully Edited!");
      })
      .catch((error: any) => {
        console.log("Error updating the document:", error);
        alert.error("Error inesperado");
      });
  }

  async function setUpAccount(type: string) {
    if (type[0] === "Ativo") {
      setAccess({ ...access, delivery: 1 });
    } else if (type[0] === "Desativado") {
      setAccess({ ...access, delivery: 0 });
    }
  }

  function clearFields() {
    setName("");
    setAccess({ ...access, delivery: 1 });
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
    setDocument("");
  }

  function handleRemoveUserOnCompany(userDocumentToHandle: string) {

    setCompanyUsers((prevCompanyUsersState: any) => {
      let aux = {}

      for (const document in prevCompanyUsersState) {

        if (document !== userDocumentToHandle) {
          aux = {
            ...aux,
            [document]: prevCompanyUsersState[document],
          }
        }
      }
      setCompany((prevCompanyState: any) => ({ ...prevCompanyState, users: aux }))
      return aux;
    })
  }

  return (
    <StyledPopupUsers
      trigger={
        <div style={{ display: "flex" }}>
          <button id="buttonOpenPopup" onClick={() => setOpenUsers(!openUsers)}>
            {children.children}
          </button>
        </div>
      }
      lockScroll
      open={openUsers}
      onClose={() => {
        setSelectedRole('manager');
        closeModalUsers()
      }}
      closeOnDocumentClick={false}
      modal
      position="top center"
    >
      <div className="content-managerAccounts">
        <div className="header-form" style={{ background: color }}>
          <legend>Lista de contas associadas - {item.name}</legend>
          <AiOutlineCloseCircle
            size={22}
            color="white"
            onClick={closeModalUsers}
            style={{
              position: "absolute",
              right: "4px",
              top: "4px",
              cursor: "pointer",
            }}
          />
        </div>

        <section className="controller-users">
          <form onSubmit={handleSubmitCreateUser} autoComplete="off">
            <div className="content-block-selectRole">
              <label>Papel </label>
              <select name="role" id="roleSelect" onChange={event => {
                setSelectedRole(event.target.value as SelectedRoleOptions);
                clearFields();
              }}>
                <option value="manager">Gerente</option>
                <option value="driver">Motorista</option>
              </select>
            </div>
            {selectedRole === 'manager' &&
              <div className="content-block-form">
                <div className="block-form-inputs">
                  <label>Nome: </label>
                  <input
                    autoComplete="off"
                    value={name}
                    required
                    type="text"
                    placeholder="Informe o nome"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    marginTop: 12,
                    display: "grid",
                    gridTemplateColumns: "25% 25% 25% 25%",
                  }}
                >
                  <div className="block-form-inputs">
                    <label>Email: </label>
                    <input
                      autoComplete="off"
                      value={email}
                      required
                      type="email"
                      placeholder="Informe o email"
                      onChange={(e: any) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="block-form-inputs">
                    <label>CPF: </label>
                    <input
                      autoComplete="off"
                      value={document}
                      required
                      type="text"
                      minLength={14}
                      maxLength={14}
                      placeholder="Informe o CPF"
                      onChange={(e: any) => {
                        const doc = MaskCPFCNPJ(e.target.value);
                        setDocument(doc);
                      }}
                    />
                  </div>
                  <div className="block-form-inputs">
                    <label>Senha: </label>
                    <input
                      autoComplete="off"
                      value={password}
                      required
                      type="password"
                      placeholder="Informe a senha"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="block-form-inputs">
                    <label>Confirme a senha: </label>
                    <input
                      autoComplete="off"
                      value={passwordConfirm}
                      className={
                        password !== passwordConfirm && passwordConfirm.length > 0
                          ? "warning"
                          : ""
                      }
                      required
                      type="password"
                      minLength={6}
                      placeholder="Confirme a senha"
                      onChange={(e) => {
                        setPasswordConfirm(e.target.value);
                      }}
                    />
                  </div>
                  <div className="block-form-inputs" style={{ marginTop: 12 }}>
                    <Checkbox
                      labels={[["Ativo"], ["Desativado"]]}
                      item={1}
                      uid={item.uid}
                      setUpAccount={setUpAccount}
                    />
                  </div>
                </div>
              </div>
            }
            {selectedRole === 'driver' &&
              <div className="content-block-form">
                <div className="block-form-inputs">
                  <label>CPF: </label>
                  <input
                    autoComplete="off"
                    value={document}
                    required
                    type="text"
                    minLength={14}
                    maxLength={14}
                    placeholder="Informe o CPF"
                    onChange={(e: any) => {
                      const doc = MaskCPFCNPJ(e.target.value);
                      setDocument(doc);
                    }}
                  />
                </div>
              </div>
            }

            <div className="form-button-painel">
              <button onClick={closeModalUsers}>Cancelar</button>
              {password !== passwordConfirm ? (
                <button
                  style={{ background: color }}
                  onClick={() => alert.error("SENHAS DIFERENTES!")}
                >
                  Cadastrar
                </button>
              ) : (
                <button type="submit" style={{ background: color }}>
                  Cadastrar
                </button>
              )}
            </div>
          </form>
          <div className="controller-users-titles">
            <legend>Nome</legend>
            <legend>Documento</legend>
            <legend>Login</legend>
            <legend>Permissão</legend>
            <legend>Ativo</legend>
          </div>
          <div style={{ overflowY: "scroll", maxHeight: 300 }}>
            {dataUsers.map((user, index) => (
              <div key={index} className="controller-users-titles">
                <Account
                  user={user}
                  company={company}
                  themeHex={themeHex}
                  closeModalUsers={closeModalUsers}
                  deleteUserOnCompany={handleRemoveUserOnCompany}
                />
              </div>
            ))}
          </div>
        </section>
      </div>
    </StyledPopupUsers>
  );
};

export { ManagerAccounts };
