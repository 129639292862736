import { UserProps } from 'components/ManagerAccounts';
import { Companies } from 'Page/PainelCompanieUsers';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type AuthContextProps = {
    currentUser: boolean;
    color: string;
    setStateCurrentUser: () => void;
    loadColor: (c: string) => void;
    loadCurrentUser: (state: boolean) => void;
    loadInfoUser: (user: UserProps | null) => void;
    userInfo?: UserInfoProps | null;

    loadCurrentUserPainel: (state: boolean) => void;
    setStateCurrentUserPainel: () => void;
    loadInfoUserPainel: (user: UserInfoPropsPainel[]) => void;
    currentUserPainel: boolean;
    userInfoPainel: UserInfoPropsPainel;

    companieInfo: Companies;
    loadCompanieInfo: (company: Companies) => void;
}

type AuthProviderProps = {
    children: ReactNode;
}

export type UserInfoProps = {
    name: string;
    document: string;
    role: string;
    driverAvailable: number;
    access: {
        delivery: boolean;
        jor: boolean;
        position: boolean;
    };
    company: string;
    password: string;
    uid: string;
    email?: string;
    phone?: string;
    acceptedAt: {
        nanoseconds: number;
        seconds: number;
    }
    deletedAt?: {
        nanoseconds: number;
        seconds: number;
    }
    typesenseApiKey?: string;
}

export type UserInfoPropsPainel = {
    login: string;
    name: string;
    password: string;
    uid: string;
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children, }: AuthProviderProps) => {
    const [currentUser, setCurrentUser] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfoProps | null>({
        name: '',
        document: '',
        role: '',
        company: '',
        password: '',
        uid: '',
        email: '',
        driverAvailable: 0,
        typesenseApiKey: '',
        access: {
            delivery: true,
            jor: true,
            position: true,
        },
        acceptedAt: {
            nanoseconds: 0,
            seconds: 0,
        },
    });
    const [companieInfo, setCompanieInfo] = useState<Companies>({
        access: {
            delivery: 0,
            position: 1,
            jor: 1,
        },
        finalizationPhotos: {
            package: false,
            stub: false,
            client: false,
        },
        name: '',
        uid: '',
        users: '',
        typesenseApiKey: ''
    });

    const [currentUserPainel, setCurrentUserPainel] = useState(false);
    const [userInfoPainel, setUserInfoPainel] = useState<UserInfoPropsPainel>({
        login: '',
        name: '',
        password: '',
        uid: ''
    });

    const [color, setColor] = useState('');

    function loadColor(c: string) {
        setColor(c);
    }
    function loadInfoUser(user: UserProps | null) {
        setUserInfo(user);
    }
    function setStateCurrentUser() {
        setCurrentUser(!currentUser);
    }
    function loadCurrentUser(state: boolean) {
        setCurrentUser(state);
    }
    function loadInfoUserPainel(user: UserInfoPropsPainel[]) {
        setUserInfoPainel(user[0]);
    }
    function setStateCurrentUserPainel() {
        setCurrentUserPainel(!currentUserPainel);
    }
    function loadCurrentUserPainel(state: boolean) {
        setCurrentUserPainel(state);
    }
    function loadCompanieInfo(company: Companies) {
        setCompanieInfo(company);
    }


    useEffect(() => {
        const painel = sessionStorage.getItem('user-painel');

        const stateUser = sessionStorage.getItem('stateUser');
        const user = sessionStorage.getItem('userInfo');

        const company = sessionStorage.getItem('companieInfo');

        if (painel) {
            setUserInfoPainel(JSON.parse(painel));
        }

        if (user) {
            setUserInfo(JSON.parse(user));
        }

        if (stateUser) {
            setCurrentUser(Boolean(stateUser));
        }

        if (company) {
            setCompanieInfo(JSON.parse(company));
        }
    }, []);

    useEffect(() => {
        if (currentUserPainel) {
            sessionStorage.setItem('user-painel', JSON.stringify(userInfoPainel));
        }
        if (currentUser) {
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            sessionStorage.setItem('stateUser', JSON.stringify(currentUser));
        }
        if (companieInfo) {
            sessionStorage.setItem('companieInfo', JSON.stringify(companieInfo));
        }
    });

    return (
        <AuthContext.Provider
            value={{
                companieInfo,
                loadCompanieInfo,
                loadCurrentUserPainel,
                setStateCurrentUserPainel,
                loadInfoUserPainel,
                currentUserPainel,
                userInfoPainel,
                currentUser,
                setStateCurrentUser,
                loadCurrentUser,
                loadInfoUser,
                userInfo,
                loadColor,
                color,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
}