export const Colors = {
  primary: '#dc702a',
  secondary: "#292623"
}

export const Images = {
  background: '',
  logo: 'https://i.imgur.com/jzgCsnr.png"',
}
export const Labels = {
  name: 'Extratta',
  nameUppercase: 'EXTRATTA',
  fone: '(49) 99988-7766',
  typesenseDocs: 'prod_extratta_documents'
}

export const Links = {
  website: 'rastergr.com.br',
  changePassword: 'https://us-central1-extratta-app.cloudfunctions.net/updatePassword',
  changeEmail: 'https://us-central1-extratta-app.cloudfunctions.net/updateEmail',
  downloadURLPreFix: 'https://firebasestorage.googleapis.com/v0/b/raster-position.appspot.com/o/',
}