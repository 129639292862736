import App from './App';
import ReactDOM from 'react-dom';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { CardRasterProvider } from './ctx/CardRasterContext';
import AlertTemplate from 'react-alert-template-basic'
import { AuthProvider } from './ctx/AuthContext';
import { UpdateProvider } from './ctx/UpdateContext';
import { ApiProvider } from './ctx/ApiContext';
import { FormsProvider } from 'ctx/useForms';
import { PaginationProvider } from 'ctx/PaginationContext';


const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  transition: transitions.FADE,
  timeout: 3000,
  containerStyle: {
    zIndex: 1000
  }
  // offset: '30px',
  // you can also just use 'scale'
}

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <ApiProvider>
      <CardRasterProvider>
        <AuthProvider>
          <UpdateProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <FormsProvider>
                <PaginationProvider>
                  <App />
                </PaginationProvider>
              </FormsProvider>
            </AlertProvider>
          </UpdateProvider>
        </AuthProvider>
      </CardRasterProvider>
    </ApiProvider>
  </DndProvider >,
  document.getElementById("root")
);
