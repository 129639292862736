import { FormEvent, useEffect, useState } from "react";
import { GoChevronRight } from "react-icons/go";
import { Redirect } from "react-router-dom";
import { AiOutlineEye, AiFillEyeInvisible } from "react-icons/ai";
import Cookies from "js-cookie";
import styled from "styled-components";
import { useAlert } from "react-alert";

// components
import { useAuth } from "ctx/AuthContext";

import useGetUsers, { useGetUserInfo } from "hooks/useGetUsers";
import GetCompanie from "hooks/useGetCompanie";

// styles
import "./index.scss";

// styles
import "./index.scss";
import firebase from "../../database/FirebaseConfig";
import { Colors, Images } from "utils/template";

function HomeEmpresas() {
  type WrapperProps = {
    bg: string;
  };

  const Wrapper = styled.div<Pick<WrapperProps, "bg">>`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(${(props) => props.bg}), var(--secondary);
    background-size: cover;
  `;

  return (
    <>
      <Wrapper bg={Images.background}>
        <img src={Images.logo} alt='Logo da marca' />
        <Login />
      </Wrapper>
    </>
  );
}

export default HomeEmpresas;

const Login = () => {
  const users = useGetUsers();
  const { getUserInfo } = useGetUserInfo();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loginResult, setStateloginResult] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { loadInfoUser, setStateCurrentUser, currentUser, userInfo, loadCompanieInfo, companieInfo } = useAuth();

  const colorBtn = Colors.secondary;
  const alert = useAlert();

  const GetCompany = async (uid: string) => {
    const db = firebase.firestore();
    try {
      const doc = await db.collection("companies").doc(uid).get();
      const companyInfo = doc.data();
      return companyInfo;
    } catch (err) {
      console.log("Não foi possível pegar a company", err);
    }
  };

  async function handleClick(event: FormEvent) {
    event.preventDefault();
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(login, password);
      if (userCredential.user?.uid) {
        const user = await getUserInfo(userCredential.user.uid);
        GetCompanie(user.company, loadCompanieInfo);
        const data = await GetCompany(user.company);

        if (user.deletedAt) {
          alert.error("Este usuário foi deletado");
          return;
        }

        if (!data?.access.delivery || !user.access.delivery) {
          alert.error("Este usuário ou companhia não está autorizado a entrar no sistema");
          return;
        }
        loadInfoUser(user);
        setStateCurrentUser();
      }
    } catch (error: any) {
      console.log(error);
      alert.error("Credenciais inválidas. Por favor, verifique suas informações e tente novamente.");
    }
  }

  useEffect(() => {
    return () => {
      users.setLoading(false);
    };
  }, [users]);

  if (currentUser) {
    Cookies.set("currentUser", String(currentUser));
    Cookies.set("company", String(userInfo?.company));
    Cookies.set("name", String(userInfo?.name));
    Cookies.set("document", String(userInfo?.document));
    Cookies.set("email", String(userInfo?.email));
    Cookies.set("apiKeyTypesense", String(companieInfo?.typesenseApiKey));
    if (userInfo?.role === "superadmin") {
      return <Redirect to='/painelCompanieUsers' />;
    } else if (userInfo?.role === "manager") {
      return <Redirect to='/index' />;
    } else {
      return <Redirect to='/' />;
    }
  } else {
    return (
      <div className='card-wrapper'>
        <form onSubmit={handleClick} className='card'>
          <div className='input-wrapper'>
            <label htmlFor='identidade' className='input-label'>
              Email
              <span className='necessary-icon'>*</span>
            </label>

            <input
              required
              id='identidade'
              type='text'
              className='input-text'
              placeholder='Digite seu email'
              maxLength={100}
              onChange={() => {
                setStateloginResult(false);
              }}
              onInput={(e: any) => {
                setLogin(e.target.value);
              }}
            />
          </div>
          <div className='input-wrapper'>
            <label htmlFor='senha' className='input-label'>
              Senha
              <span className='necessary-icon'>*</span>
            </label>

            <span>
              {showPassword ? (
                <AiOutlineEye onClick={() => setShowPassword(!showPassword)} className='password-view' />
              ) : (
                <AiFillEyeInvisible onClick={() => setShowPassword(!showPassword)} className='password-view' />
              )}
            </span>
            <input
              id='senha'
              type={showPassword ? "text" : "password"}
              className='input-text'
              placeholder='************'
              maxLength={18}
              required
              onChange={(e: any) => {
                setPassword(e.target.value);
                setStateloginResult(false);
              }}
            />
          </div>

          {loginResult && <p className='login-alert'>Conta inválida!</p>}
          <button type='submit' className='access-button button-wrapper' style={{ background: colorBtn }}>
            <GoChevronRight className='button-icon' />
            Acessar
          </button>
        </form>
      </div>
    );
  }
};
