// eslint-disable-next-line
import { ColorPicker, useColor } from "react-color-palette";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AiFillNotification, AiOutlineCloseCircle } from "react-icons/ai";
import { FiPlus } from "react-icons/fi";
import { GiPlainCircle } from "react-icons/gi";
import Cookies from "js-cookie";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { useAlert } from "react-alert";
import firebase, { db } from "../../database/FirebaseConfig";

// components
import { ManagerAccounts } from "components/ManagerAccounts";
import { Occurrence } from "../CreateOccurence";
import { InfoCards } from "../InfoCards";

// ctx
import { useApi } from "ctx/ApiContext";
import { useAuth } from "ctx/AuthContext";
import { useUpdate } from "ctx/UpdateContext";
import { useCard } from "ctx/CardRasterContext";

// hooks
import useGetTheme from "hooks/useGetTheme";
import { useUsers } from "hooks/useGetUsers";
import HandleImageIntoCloudStorage from "hooks/useGetImages";
import { UpdateAllOccurrencesToCheck } from "hooks/useUpdateNotifications";
import UseUpdateColorTheme, { ResetChanges } from "hooks/useUpdateColorTheme";

// assets
// import checkbox from 'assets/icons/checkboxBlue.svg';
import dropSelect from "assets/icons/dropmenu.svg";
import alertSVG from "assets/icons/notification.svg";

// styles
import "./index.scss";
import "react-color-palette/lib/css/styles.css";
import { Configurations } from "components/Configurations";
import { MessageOutlined, WechatOutlined } from "@ant-design/icons";
import secondsToDate, { secondsToTime } from "utils/timestampToDate";
import { Colors, Images } from "utils/template";

export type OccurrenteProps = {
  finish: boolean;
  images: boolean;
  info: string;
  name: string;
  uid: string;
};

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
    width: 1200px;

    @media (max-width: 560px) {
      width: 75%;
    }
  }
`;
const PopupThree = styled(Popup)`
  &-content {
    width: 30px;
    padding: 0px;
    border: none;
    border-radius: 18px;
  }
`;

const StyledPopupNotification = styled(Popup)`
  &-content {
    width: 1200px;
    padding: 0px;
    border: none;
    border-radius: 16px;
    @media (max-width: 560px) {
      width: 75%;
    }
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export function Header() {
  // Controller open or close area of custom page
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { users } = useUsers();

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const closeModal = () => {
    setIsOpen(false);
    setIsOpenMenu(false);
  };
  const [isOpenUsers, setIsOpenUsers] = useState<boolean>(false);
  const closeModalUsers = () => setIsOpenUsers(false);

  const [isOpenOccurences, setIsOpenOccurences] = useState<boolean>(false);
  const [isOpenConfigurations, setIsOpenConfigurations] = useState<boolean>(false);
  const closeModalOccurence = () => setIsOpenOccurences(false);
  const closeModalConfigurations = () => setIsOpenConfigurations(false);

  const [openInfoUser, setOpenInfoUser] = useState<boolean>(false);
  const closeModalInfoUser = () => setOpenInfoUser(false);

  const { update } = useUpdate();

  const { loadInfoUser, setStateCurrentUser, companieInfo, userInfo } =
    useAuth();

  const logo = useRef<any>(null);
  const { clearIds } = useCard();
  const theme = useGetTheme();
  const themeHex = Cookies.get("colorTheme");
  document.documentElement.style.setProperty("--secondary", String(themeHex));
  const name = Cookies.get("name");
  const company = Cookies.get("company");

  function handleClose() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (company !== undefined) {
      theme.getTheme({
        company,
        onComplete: (theme: any) => {
          if (theme === undefined) {
            Cookies.set("colorTheme", Colors.secondary);
            Cookies.set(
              "logo",
              Images.logo
            );
            Cookies.set(
              "background",
              Images.background,
            );
            logo.current = undefined;
            document.documentElement.style.setProperty(
              "--secondary",
              "--secondary"
            );
          } else {
            Cookies.set("background", theme.background);
            Cookies.set("colorTheme", theme.color);
            Cookies.set("logo", theme.logo);
            logo.current = theme.logo;
            document.documentElement.style.setProperty("--secondary", theme);
          }
        },
      });
    }

    return () => {
      theme.setLoading(false);
    };
  }, [update]);


  return (
    <div className="headerPage">
      <div className="firstBlock">
        <img
          src={
            logo.current ||
            Images.logo
          }
          alt="Logo of brand"
        />
      </div>

      <div className="secondBlock">
        <div className="Notification tooltipBoundaryNotification">
          <ChatNotification />
        </div>
        <div className="Notification tooltipBoundaryNotification">
          <Notification />
        </div>

        <div className="menuRight tooltipBoundary">
          <span className="circle">
            {name !== undefined ? name.substr(0, 2).toLocaleUpperCase() : ""}
          </span>
          <PopupThree
            trigger={
              <div>
                <button
                  type="button"
                  className="button"
                  id="buttonOpenPopup"
                  onClick={() => setIsOpenMenu((o) => !o)}
                >
                  <img src={dropSelect} alt="imagem select menu" />
                </button>
              </div>
            }
            className="mypopupHeader"
            onClose={closeModal}
            open={isOpenMenu}
            arrow={false}
            closeOnDocumentClick={!(isOpenUsers || isOpenOccurences || isOpenConfigurations)}
            position={"bottom right"}
            lockScroll
          >
            <div className="content-menu-topRight">
              <AiOutlineCloseCircle
                size={14}
                style={{
                  position: "absolute",
                  right: "4px",
                  top: "4px",
                  cursor: "pointer",
                  marginBottom: 10,
                }}
                onClick={closeModal}
              />
              {!!userInfo?.role &&
                (
                  <>
                    <div className="blockRightMenu">
                      <p>{name}</p>
                      <ManagerAccounts
                        // users={companieInfo.users}
                        item={companieInfo}
                        closeModalUsersFromHeader={closeModalUsers}
                        themeHex={themeHex}
                      >
                        <span
                          style={{ textAlign: "start" }}
                          onClick={() => {
                            setIsOpenUsers((o) => !o);
                          }}
                        >
                          Usuários
                        </span>
                      </ManagerAccounts>
                    </div>

                    <div className="blockRightMenu">
                      <StyledPopup
                        trigger={
                          <div>
                            <button
                              type="button"
                              className="button"
                              id="buttonOpenPopup"
                              onClick={() => {
                                setIsOpenOccurences((o) => !o);
                              }}
                            >
                              <span>Ocorrências</span>
                            </button>
                          </div>
                        }
                        lockScroll
                        className="mypopupHeader"
                        open={isOpenOccurences}
                        onClose={closeModalOccurence}
                        modal
                        closeOnDocumentClick={false}
                      >
                        <Occurrence closeModal={closeModalOccurence} />
                      </StyledPopup>
                    </div>

                    <div className="blockRightMenu">
                      <StyledPopup
                        trigger={
                          <div>
                            <button
                              type="button"
                              className="button"
                              id="buttonOpenPopup"
                              onClick={() => {
                                setIsOpenConfigurations((o) => !o);
                              }}
                            >
                              <span>Configurações</span>
                            </button>
                          </div>
                        }
                        lockScroll
                        className="mypopupHeader"
                        open={isOpenConfigurations}
                        onClose={closeModalConfigurations}
                        modal
                        closeOnDocumentClick={false}
                      >
                        <Configurations closeModal={closeModalConfigurations} />
                      </StyledPopup>
                    </div>
                  </>
                )}
              <div className="blockRightMenu">
                <Link
                  to="/"
                  onClick={() => {
                    clearIds();
                    loadInfoUser(null);
                    setStateCurrentUser();
                    firebase.auth().signOut();
                    Cookies.remove("name");
                    Cookies.remove("email");
                    Cookies.remove("document");
                    Cookies.remove("company");
                    localStorage.removeItem("companieInfo");
                    localStorage.removeItem("userInfo");
                    sessionStorage.removeItem("user-painel");
                    sessionStorage.removeItem('state-user');
                    sessionStorage.removeItem('stateUser');
                    sessionStorage.removeItem('userInfo');
                    setStateCurrentUser();
                    Cookies.set("currentUser", "");
                    Cookies.set("name", "");
                  }}
                >
                  Sair
                </Link>
              </div>
            </div>
          </PopupThree>
        </div>
      </div>
    </div>
  );
}

const ChatNotification = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const closeModal = () => setIsOpenMenu(false);
  const company = Cookies.get("company");
  const [hasNewMessage, setHasNewMessage] = useState(false);

  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const closeModalNotificationItem = () => {
    closeModal();
    setIsOpenNotification(false);
  };
  const { totalNotification, allLastMessages, chatIsOpen } = useApi();


  useEffect(() => {
    const unsubscribe = db.collection('companies').doc(company).onSnapshot(snapshot => {
      const chat = snapshot.data()?.chat;

      if (chat === undefined) return;

      if (chat?.lastRead?.seconds < chat?.lastReceived?.seconds) {
        setHasNewMessage(true);
      } else {
        setHasNewMessage(false);
      };
    })
    return () => {
      unsubscribe();
    }
  }, []);

  async function updateChatOnCompany() {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    try {
      await db.collection('companies').doc(company).set({
        chat: {
          lastRead: timestamp
        }
      }, { merge: true })
      setHasNewMessage(false);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {allLastMessages.length === 0 ? (
        <div>
          <button
            type="button"
            className="button"
            id="buttonOpenPopup"
          >
            <MessageOutlined className="messageIcon" />
            <span className=""></span>
          </button>
        </div>
      ) : (
        <Popup
          trigger={
            <div>
              <button
                type="button"
                className="button"
                id="buttonOpenPopup"
                onClick={() => setIsOpenMenu((o) => !o)}
              >
                <MessageOutlined className="messageIcon" />
                {hasNewMessage ? (
                  <span className="Alert"></span>
                ) : (
                  <span className=""></span>
                )}
              </button>
            </div>
          }
          className="mypopupNotificattion myChatpopupNotificattion"
          onClose={closeModal}
          open={isOpenMenu}
          closeOnDocumentClick={!isOpenNotification}
          keepTooltipInside=".tooltipBoundaryNotification"
          lockScroll
          onOpen={updateChatOnCompany}
        >
          <div className="chatAlertPopup">
            <span>Mensagens</span>
            <AiOutlineCloseCircle
              size={14}
              style={{
                position: "absolute",
                right: "4px",
                top: "4px",
                cursor: "pointer",
              }}
              onClick={closeModal}
            />
            {allLastMessages.map((item: any, index: number) => {
              if (item.check === true) {
                return <div key={index}></div>;
              }
              return (
                <LastMessageNotification
                  key={index}
                  item={item}
                  references={item.occurrences}
                  closeModalNotificationItem={closeModalNotificationItem}
                  setIsOpenNotification={setIsOpenNotification}
                  closeChatNotificationModal={closeModal}
                />
              );
            })}
          </div>
        </Popup>
      )}
    </>
  );
};

const Notification = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const closeModal = () => setIsOpenMenu(false);

  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const closeModalNotificationItem = () => {
    closeModal();
    setIsOpenNotification(false);
  };
  const { allNotifications } = useApi();

  const totalNotification = allNotifications.length

  return (
    <>
      {totalNotification === 0 ? (
        <div>
          <button
            type="button"
            className="button"
            id="buttonOpenPopup"
          >
            <img src={alertSVG} alt="Counter of notifications" />
            <span className=""></span>
          </button>
        </div>
      ) : (
        <Popup
          trigger={
            <div>
              <button
                type="button"
                className="button"
                id="buttonOpenPopup"
                onClick={() => setIsOpenMenu((o) => !o)}
              >
                <img src={alertSVG} alt="Counter of notifications" />
                {totalNotification !== 0 ? (
                  <span className="Alert">{totalNotification}</span>
                ) : (
                  <span className=""></span>
                )}
              </button>
            </div>
          }
          className="mypopupNotificattion"
          onClose={closeModal}
          open={isOpenMenu}
          closeOnDocumentClick={!isOpenNotification}
          keepTooltipInside=".tooltipBoundaryNotification"
          lockScroll
        >
          <div className="alertPopup">
            <span>Notificações</span>
            <AiOutlineCloseCircle
              size={14}
              style={{
                position: "absolute",
                right: "4px",
                top: "4px",
                cursor: "pointer",
              }}
              onClick={closeModal}
            />
            {allNotifications.map((item: any, index: number) => {
              if (item.check === true) {
                return <div key={index}></div>;
              }
              return (
                <OccurenceNotification
                  key={index}
                  item={item}
                  closeModalNotificationItem={closeModalNotificationItem}
                  setIsOpenNotification={setIsOpenNotification}
                />
              );
            })}
          </div>
        </Popup>
      )}
    </>
  );
};

function LastMessageNotification({
  item,
  index,
  closeModalNotificationItem,
  setIsOpenNotification,
}: any) {
  const [oneSelectPopup, setOneSelectPopup] = useState(false);
  const [doc, setDoc] = useState<any>()
  const company = Cookies.get("company");
  const [messageReaded, setMessagedReaded] = useState(false);
  const messageTime = secondsToTime(item.createdAt);

  useEffect(() => {
    const unsubscribeChat = db.collection('chats').doc(item.docId).onSnapshot(snapshot => {
      if (snapshot.data()?.lastRead === undefined) return;
      const chatLastRead = snapshot.data()!.lastRead[company!];
      const messageCreated = item?.createdAt
      const messageWasReaded = chatLastRead?.seconds >= messageCreated?.seconds;
      setMessagedReaded(messageWasReaded);
    })
    const unsubscribeDoc = db.collection('documents').doc(item.docId).onSnapshot(snapshot => {
      const docData = {
        uid: snapshot.id,
        ...snapshot.data()
      }
      setDoc(docData);
    })
    return () => {
      unsubscribeChat();
      unsubscribeDoc();
    }
  }, [])

  const closeModal = () => {
    setOneSelectPopup(false);
    closeModalNotificationItem();
  };

  async function updateLastReadOnChat() {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    if (company === undefined) return;
    await db.collection('chats').doc(doc.uid).set({
      lastRead: {
        [company]: timestamp
      }
    }, { merge: true })
  }

  return (
    <>
      {!!doc &&
        <StyledPopupNotification
          trigger={
            <div>
              <button
                type="button"
                className="button"
                id="buttonOpenPopupNotification"
                onClick={() => {
                  setIsOpenNotification((o: any) => true);
                  setOneSelectPopup((o: any) => true);
                }}
              >
                <div
                  className={
                    !messageReaded
                      ? "blockMsg blockMsg-focus"
                      : "blockMsg"
                  }
                  key={index}
                >
                  <GiPlainCircle
                    color={!messageReaded ? "red" : "green"}
                    style={{ paddingTop: "6px" }}
                    size={22}
                  />
                  <div className="msgAlert" style={{ position: 'relative', width: '100%' }}>
                    <b style={{ whiteSpace: 'nowrap', width: '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {doc.nameDocument}
                    </b>
                    <span style={{ width: 330, display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--textGrayCard)', }}>
                      <b style={{ whiteSpace: 'nowrap', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--textGrayCard)' }}>{item.user.name}:</b>
                      {item.message}
                    </span>
                    <span style={{ alignSelf: 'flex-end', fontSize: '0.8rem', color: 'var(--textGray)', position: 'absolute', bottom: 0, right: 0 }}>
                      {messageTime}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          }
          modal
          key={index}
          open={oneSelectPopup}
          onOpen={updateLastReadOnChat}
          className="bodynotification"
          closeOnDocumentClick={false}
          onClose={() => {
            closeModal();
          }}
          lockScroll
        >
          <div className="alloccurrence">
            <AiOutlineCloseCircle
              size={18}
              color="white"
              style={{
                position: "absolute",
                right: "4px",
                top: "4px",
                cursor: "pointer",
                marginBottom: 10,
              }}
              onClick={closeModal}
            />
            {doc && (
              <div className="mainInformation">
                <InfoCards
                  handleClick={() => { }}
                  cardProps={doc}
                  closeIcon={false}
                  isOpenOccurence={false}
                />
              </div>
            )}
          </div>
        </StyledPopupNotification>
      }
    </>
  );
}

function OccurenceNotification({
  item,
  index,
  closeModalNotificationItem,
  setIsOpenNotification,
}: any) {
  const { getOneDoc } = useCard();
  const alert = useAlert();
  const [oneSelectPopup, setOneSelectPopup] = useState(false);

  const closeModal = () => {
    if (item.occurrences === '') {
      UpdateAllOccurrencesToCheck(item.uid);
    }
    setOneSelectPopup(false);
    closeModalNotificationItem();
  };

  return (
    <>
      <StyledPopupNotification
        trigger={
          <div>
            <button
              type="button"
              className="button"
              id="buttonOpenPopupNotification"
              onClick={() => {
                setIsOpenNotification((o: any) => !o);
                setOneSelectPopup((o: any) => !o);
              }}
            >
              <div
                className={"blockMsg blockMsg-focus"}
                key={index}
              >
                <GiPlainCircle
                  color={"red"}
                  style={{ paddingTop: "6px" }}
                  size={22}
                />
                <div className="msgAlert" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <span style={{ fontWeight: 'bold' }}>Nova ocorrência</span>
                  <span >
                    {item.type === 1 ? "Coleta" : "Entrega"} {item.cod}
                  </span>
                </div>
              </div>
            </button>
          </div>
        }
        modal
        key={index}
        open={oneSelectPopup}
        className="bodynotification"
        closeOnDocumentClick={false}
        onClose={() => {
          closeModal();
        }}
        lockScroll
      >
        <div className="alloccurrence">
          <AiOutlineCloseCircle
            size={18}
            color="white"
            style={{
              position: "absolute",
              right: "4px",
              top: "4px",
              cursor: "pointer",
              marginBottom: 10,
            }}
            onClick={closeModal}
          />
          {item && (
            <div className="mainInformation">
              <InfoCards
                handleClick={() => { }}
                cardProps={item}
                closeIcon={false}
                isOpenOccurence={false}
              />
            </div>
          )}
        </div>
      </StyledPopupNotification>
    </>
  );
}
