import firebase from "../database/FirebaseConfig";

// interface
import { CardRasterProps, CardRasterPropsSend } from "../types/InterfaceCard";

const db = firebase.firestore();

async function finishCard(card: CardRasterProps, userInfo?: any) {
  db.collection("documents")
    .doc(card.uid)
    .update({
      status: 4,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
  if (!userInfo) return;
  db.collection("documents").doc(card.uid).collection('documentEvents').add({
    createdAt: firebase.firestore.Timestamp.now(),
    status: 4,
    user: {
      id: userInfo.uid,
      name: userInfo.name,
      role: userInfo.role
    }
  })
}

function UseUpdateXMLDocument(uid: string, url: string) {
  db.collection("documents")
    .doc(uid)
    .update({
      urlXml: url,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}
function UpdateHistory(
  card: CardRasterProps,
  driver: string,
  cpf: string,
  nameRomaneio: string
) {
  const createdAt = new Date();

  const formart = {
    nameRomaneio,
    createdAt,
    driver,
    document: cpf,
  };

  var data: any = [];
  data.push(formart);

  db.collection("documents")
    .doc(card.uid)
    .update({
      intoRomaneio: true,
      history: data,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateConcatHistory(uid: string, data: any) {
  db.collection("documents")
    .doc(uid)
    .update({
      intoRomaneio: true,
      history: data,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdatePositionIdCard(uid: string, id: string) {
  db.collection("documents")
    .doc(uid)
    .update({
      id,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateCardsToIntoRomaneio(card: CardRasterProps, name: string) {
  db.collection("documents")
    .doc(card.uid)
    .update({
      intoRomaneio: true,
      currentRomaneio: name,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
  db.collection('chats').doc(card.uid).set({
    lastRead: {
      [card.company]: firebase.firestore.Timestamp.now()
    }
  })
}

async function RemoveStateWarningInCard(uid: string) {
  db.collection("documents")
    .doc(uid)
    .update({
      status: 5,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}
async function UpdateCardsToReturnDelivery(uid: string) {
  db.collection("documents")
    .doc(uid)
    .update({
      status: 7,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

async function UpdateCardsToOutRomaneio(card: CardRasterProps) {
  db.collection("documents")
    .doc(card.uid)
    .update({
      intoRomaneio: false,
      currentRomaneio: "",
      status: 1,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}
async function UpdateCardsToOutRomaneioAndRemoveStatusReturn(
  card: CardRasterProps
) {
  db.collection("documents")
    .doc(card.uid)
    .update({
      intoRomaneio: false,
      currentRomaneio: "",
      status: 1,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function CancelCard(card: CardRasterProps) {
  const db = firebase.firestore();

  // db.collection("documents")
  //   .doc(card.id)
  //   .update({
  //     type: 7,
  //   })
  //   .then(() => {
  //     console.log("Profile Successfully Edited!");
  //   })
  //   .catch((error: any) => {
  //     console.log("Error updating the document:", error);
  //   });
  db.collection("documents")
    .doc(card.id)
    .delete()
    .then(() => {
      console.log("Document Successfully Removed!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

async function UpdateCardRomaneiosField(
  length: number,
  carRomaneios: any,
  uid: string
) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update({
      numbers: length,
      cardsRomaneios: carRomaneios,
      total_demands: length
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

async function UpdateAllFields(card: CardRasterPropsSend, uid: string) {
  const db = firebase.firestore();
  try {
    db.collection("documents")
      .doc(uid)
      .update(card)
      .then(() => {
        console.log("Profile Successfully Edited!");
      })
      .catch((error: any) => {
        console.log("Error updating the document:", error);
      });
  } catch (e) {
    console.log('Errror on updating fields (UpdateFields function):\n', e)
  }
}

export {
  UpdateCardsToOutRomaneioAndRemoveStatusReturn,
  UpdateCardsToReturnDelivery,
  UpdateCardsToIntoRomaneio,
  UpdateCardRomaneiosField,
  UpdateCardsToOutRomaneio,
  RemoveStateWarningInCard,
  UpdatePositionIdCard,
  UpdateConcatHistory,
  UpdateAllFields,
  CancelCard,
  UpdateHistory,
  UseUpdateXMLDocument,
  finishCard as default,
};
