import { db } from "database/FirebaseConfig";

export async function getAllLastMessages(companyId: string, setAllLastMessages: any) {
  try {
    // await db.collection('chats').where('companyId', '==', companyId).onSnapshot(snapshot => {
    //   const allMessages = snapshot.docs.map(doc => ({
    //     ...doc.data().lastMessage,
    //     docId: doc.id
    //   })).sort((a, b) => {
    //     if (a.createdAt < b.createdAt) return 1;
    //     if (a.createdAt > b.createdAt) return -1;
    //     return 0;
    //   });
    //   setAllLastMessages(allMessages);
    // })
    await db.collection('chats').where('companyId', '==', companyId).orderBy('lastMessage.createdAt', 'desc').onSnapshot(snapshot => {
      const allMessages = snapshot.docs.map(doc => ({
        ...doc.data().lastMessage,
        docId: doc.id
      })).sort((a, b) => {
        if (a.createdAt < b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        return 0;
      });
      setAllLastMessages(allMessages);
    })
  } catch (error) {
    console.log(error);
  }
}