import { useEffect, useState } from 'react';
import { BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';
import { AiOutlineSend } from 'react-icons/ai';
import { useAlert } from 'react-alert';

// components
import checkbox from 'assets/icons/checkboxBlue.svg';

import { useUpdate } from 'ctx/UpdateContext';
import { useApi } from 'ctx/ApiContext';

import { removeRowOccurence, } from 'hooks/useDeleteOcurrence';
import { UpdateAllFieldsOccurence } from 'hooks/useUpdateOccurences';

// styles
import './index.scss';
import { confirmAlert } from 'react-confirm-alert';
import { validateEmails } from 'utils/validateEmails';

export type OccurrenteProps = {
    finish: boolean;
    document: string;
    images: boolean;
    info: string;
    name: string;
    uid: string;
    notificationEmails?: string;
}

type OccurrencesProps = {
    closeModal: () => void;
}

export function Occurences({ closeModal }: OccurrencesProps) {
    const { allOcc } = useApi();

    return (
        <div className="alloccurrence">
            <div className="main-alloccurrence">
                <legend style={{ marginRight: 12 }}>Lista de ocorrências</legend>
                <div className="titlesOccurrence">
                    {allOcc?.length === 0 && (
                        <>
                            <p>Ocorrência</p>
                            <p>Obriga foto</p>
                            {/* <p>Não permimte finalizar</p> */}
                            <p >Descrição </p>
                        </>
                    )}
                </div>
                {allOcc !== undefined && allOcc.map((item: OccurrenteProps, index: number) => (
                    <Occurence item={item} index={index} key={index} closeModal={closeModal} />
                ))}
            </div>
        </div>
    )
}

type OccurencesProps = {
    item: OccurrenteProps;
    index: number;
    closeModal: () => void;
}

export const Occurence = ({ item, index, closeModal }: OccurencesProps) => {
    const alert = useAlert();

    const [name, setName] = useState(item.name);
    const [image, setImage] = useState(item.images);
    const [finish, setFinish] = useState(item.finish);
    const [description, setDescription] = useState(item.info);
    const [emailsUpdated, setEmailsUpdated] = useState(item.notificationEmails ?? '');

    const [changeItem, setChangeItem] = useState(false);

    useEffect(() => {
        setName(item.name);
        setImage(item.images);
        setFinish(item.finish);
        setDescription(item.info);
        setEmailsUpdated(item.notificationEmails ?? '');
    }, [item])


    const { refreshPage } = useUpdate();

    function handleDeleteOccurence(item: any) {
        removeRowOccurence(item.uid);
        refreshPage();
    }

    function handleUpdate(uid: string) {
        const descriptionIsEmpty = description === "" ? "Sem descrição." : description;
        if (emailsUpdated !== '') {
            const invalidEmails = validateEmails(emailsUpdated)

            invalidEmails.forEach(mail => alert.error(`Invalid email:\n${mail}`));
            if (invalidEmails.length !== 0) return;
        }

        const data = {
            name,
            info: descriptionIsEmpty,
            finish,
            images: image,
            notificationEmails: emailsUpdated,
        }
        UpdateAllFieldsOccurence(data, uid);
        setChangeItem(false);
        refreshPage();
        alert.success('Ocorrência atualizada!');

    }

    return (
        <div className="blockAllOcurrence" key={index}>
            <div className="changeCard">
                {index === 0 && (
                    <legend>Ocorrência</legend>
                )}
                <input
                    value={name}
                    hidden={!changeItem}
                    onChange={event => setName(event.target.value)}
                    type="text" />
                <span hidden={changeItem}>{name}</span>
            </div>
            <div className="blockCheckbox">
                {index === 0 && (
                    <legend>Foto</legend>
                )}

                {changeItem ? (
                    <>
                        <label
                            className={image ? 'label-active' : ''}
                        >
                            <input hidden={image} type="checkbox" onClick={() => setImage(!image)} />
                            <img hidden={!image} src={checkbox} alt="checkbox true" />
                        </label>
                    </>
                ) : (
                    <>
                        <label className={image ? 'label-active' : ''}>
                            <input hidden={image} type="checkbox" />
                            <img hidden={!image} src={checkbox} alt="checkbox true" />
                        </label>
                    </>
                )}
            </div>
            {/* <div className="blockCheckbox"> !------- DISABLED FINALIZATION CONFIG ------- !
                {index === 0 && (
                    <legend>Finalização</legend>
                )}
                {changeItem ? (
                    <label className={finish ? 'label-active' : ''}>
                        <input hidden={finish} type="checkbox" onClick={() => setFinish(!finish)} />
                        <img hidden={!finish} src={checkbox} alt="checkbox true" />

                    </label>
                ) : (
                    <label className={finish ? 'label-active' : ''}>
                        <input hidden={finish} type="checkbox" />
                        <img hidden={!finish} src={checkbox} alt="checkbox true" />
                    </label>
                )}

            </div> */}
            <div className="infosAllOcurrence">
                {index === 0 && (
                    <legend >Descrição </legend>
                )}

                {changeItem ? (
                    <textarea

                        value={description}
                        onChange={event => setDescription(event.target.value)}
                    />
                ) : (
                    <label>{description}</label>
                )}
            </div>
            <div className="infosAllOcurrence">
                {index === 0 && (
                    <legend >Email(s) </legend>
                )}

                {changeItem ? (
                    <textarea
                        value={emailsUpdated}
                        onChange={event => setEmailsUpdated(event.target.value)}
                    />
                ) : (
                    <label>{emailsUpdated}</label>
                )}
            </div>

            <div  // New Buttons
                className="blockIconButtonsOcurrences"
                style={index === 0 ? { paddingTop: '1.3rem' } : { paddingTop: '0' }}
            >
                {!changeItem &&
                    <BsPencilSquare
                        style={{ cursor: 'pointer', marginRight: '0.5rem', color: 'var(--secondary)' }}
                        onClick={() => setChangeItem(!changeItem)}
                    />
                }

                {changeItem ? (
                    <div>
                        <div className="sendButtonOccurences" onClick={() => handleUpdate(item.uid)}>
                            Salvar
                        </div>
                        <div className="cancelButtonOcurrences" onClick={() => setChangeItem(!changeItem)}>
                            Cancelar
                        </div>
                    </div>
                ) : (<></>
                    // <BsFillTrashFill
                    //     style={{ cursor: 'pointer', color: 'var(--secondary)' }}
                    //     onClick={() => {
                    //         confirmAlert({
                    //             message: 'Deseja remover esta ocorrência?',
                    //             buttons: [
                    //                 {
                    //                     label: 'Cancelar',
                    //                     onClick: () => ('Cancelar')
                    //                 },
                    //                 {
                    //                     label: 'Sim',
                    //                     onClick: () => {
                    //                         handleDeleteOccurence(item);
                    //                     }
                    //                 }
                    //             ],
                    //             overlayClassName: 'confirmAlert'
                    //         });
                    //     }}
                    // />
                )}
            </div>
        </div >
    )
}
