import { useEffect, useLayoutEffect, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";

// components
import { CardRaster } from "components/CardRaster";
import { useCard } from "./CardRasterContext";
import { db } from "database/FirebaseConfig";
// import { useUpdate } from "./UpdateContext";

// interface

export default function DND({ checkbox, removeDocumentIntoRomaneio, cardsOfRomaneioReferences, setDataRomaneiosCard, romaneioGeneralData, setOrderChanged }: any) {
  // handle structe of array 
  const { openInfo } = useCard();
  const [data, setData] = useState<any>([]);
  const [calcuHeightToString, setCalcuHeightToString] = useState<any>();
  const [calcuBoxToWidth, setCalcuBoxToWidth] = useState<any>();
  const [loading, setLoading] = useState(false);
  const width = useWindowSize();

  function onChange(sourceId: any, sourceIndex: any, targetIndex: any, targetId: any) {
    const nextState = swap(data, sourceIndex, targetIndex);
    if (nextState === data) return;
    setData(nextState);
    setDataRomaneiosCard(nextState);
    setOrderChanged(true);
  }

  useEffect(() => {
    const unsubscribeDocsSnapshot = db
      .collection('documents')
      .where('currentRomaneio', '==', romaneioGeneralData)
      .orderBy('id', 'asc')
      .onSnapshot(snap => {
        const docsData = snap.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        }))
        setData(docsData);
        setDataRomaneiosCard(docsData);
      })
    return unsubscribeDocsSnapshot
  }, [])

  useEffect(() => {
    if (data.length === 0) return;
    const elements = Math.floor(width[0] / 220);
    const calcuHeight = 430 * (Math.ceil(data.length / elements));
    setCalcuHeightToString(calcuHeight + 'px');
    setCalcuBoxToWidth((Math.round(width[0] / 235)))
  }, [data])


  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const disableselect = (e: any) => {
    return false
  }

  return (
    <>
      {data.length > 0 && !loading ?
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={calcuBoxToWidth}
            rowHeight={430}
            style={{ height: calcuHeightToString }}
            disableDrag={openInfo}
            disableDrop={openInfo}
          >
            {data.map((item: any) => (
              <GridItem
                style={{
                  width: 220,
                  userSelect: 'none',
                }}
                key={item.uid}
                className='CardRaster'
              >
                <CardRaster
                  key={item.uid}
                  checkBox={checkbox ? false : true}
                  card={item}
                  removeDocumentIntoRomaneio={removeDocumentIntoRomaneio}
                />
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
        :
        <h3 style={{ alignSelf: 'center', textAlign: 'center', margin: '2rem' }}>Carregando...</h3>
      }
    </>
  );
}