import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

// components
import { default as UseCreateOcurrence } from 'hooks/useCreateOcurrence';
import { Occurences } from '../Occurences';

import checkbox from 'assets/icons/checkboxBlue.svg';

// styles
import './index.scss';
import { validateEmails } from 'utils/validateEmails';
import { useApi } from 'ctx/ApiContext';

type OccurrenteProps = {
    Target?: any;
    closeModal: any;
}

export function Occurrence({ closeModal }: OccurrenteProps) {
    const alert = useAlert();

    const [finish, setFinish] = useState(false);
    const [images, setImages] = useState(false);

    const [isOpenRequestedPhotos, setIsOpenRequestedPhotos] = useState(false);
    const [info, setInfo] = useState('');
    const [nameOcurrence, setNameOcurrence] = useState('');
    const [emails, setEmails] = useState('');

    const theme = Cookies.get("colorTheme");
    document.documentElement.style.setProperty('--secondary', String(theme));

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        const company = Cookies.get("company");

        const invalidsEmail = validateEmails(emails);

        if (invalidsEmail.length !== 0) {
            invalidsEmail.forEach((email: any) => {
                alert.error(`Email invalido!\n${email}`);
            })
            return;
        }

        const data = {
            company,
            name: nameOcurrence,
            info,
            finish,
            images,
            notificationEmails: emails
        }
        UseCreateOcurrence(data);
        setNameOcurrence('');
        setInfo('');
        setEmails('');
        setFinish(false);
        alert.success('Ocorrência salva!');
    }
    return (
        <div className="occurrence" >
            <div className="header-occurrence">
                <span>Cadastrar ocorrências </span>
                <AiOutlineCloseCircle onClick={() => closeModal()} size={22} style={{ cursor: 'pointer' }} />
            </div>
            <form className="form-occurrence" onSubmit={handleSubmit}>
                <div className="nameOccurence">
                    <legend>Ocorrência</legend>
                    <input
                        value={nameOcurrence}
                        required
                        type="text"
                        maxLength={20}
                        placeholder="Informe o nome da ocorrência"
                        onChange={event => setNameOcurrence(event.target.value)}
                    />
                </div>
                <div className="checkbox-occurrence">
                    <label className={images ? 'label-active' : ''}>
                        <input type="checkbox" onClick={() => {
                            setImages(!images);
                        }} />
                        Obrigar foto
                        {images === true && (
                            <img src={checkbox} alt="checkbox true" onClick={() => {
                            }} />
                        )}
                    </label>
                    {/* <label className={finish ? 'label-active' : ''}>
                        <input type="checkbox" onClick={() => {
                            setFinish(!finish);
                        }} />
                        Não permitir finalizar
                        {finish === true && (
                            <img src={checkbox} alt="checkbox true" onClick={() => {
                            }} />
                        )}
                    </label> */}
                </div>
                <div className="block-occurrence">
                    <span>Informações adicionais</span>
                    <textarea placeholder="informações adicionais"
                        onChange={event => setInfo(event.target.value)}
                        value={info}
                        maxLength={100}
                        required
                    >
                    </textarea>
                </div>
                <div className="block-occurrence">
                    <span>E-mails de notificação (separados por vírgula se mais de um)</span>
                    <textarea placeholder="informações adicionais"
                        onChange={event => setEmails(event.target.value)}
                        value={emails}
                        maxLength={100}
                        required
                    >
                    </textarea>
                </div>
                <div className="button-form">
                    <button type="button" onClick={() => closeModal()}>Cancelar</button>
                    <button type="submit">Salvar</button>
                </div>
            </form>
            <div className="allOccurence">
                <Occurences closeModal={closeModal} />
            </div>
        </div>
    )
}